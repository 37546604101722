<template>
  <div v-if="loadPage && challenge" class="container mb-6 pb-4">
    <div v-if="showOutOfVisiblityMap" class="card overflow-y" :class="{'card-shadow-modifier': stageIndexProp}">
      <div class="card-content pt-1" :class="{'px-3': stageIndexProp, 'px-0': stageIndexProp}">
        <CardNavigation v-if="!stageIndexProp"/>
        <div class="container has-text-centered">
          <div class="notranslate is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color mb-5">
            {{ challenge.name }}
          </div>
          <b v-if="challenge.position.shownOnMap">You must be within the orange circle to view this {{ $store.state.uiMods.task.toLowerCase() }}</b>
          <b v-else>Location for this {{ $store.state.uiMods.task.toLowerCase() }} is hidden</b>
        </div>
        <task-map :challenge="challenge" :mapHeight="250" />
      </div>
    </div>
    <div v-else-if="showChallengeTimer" class="card overflow-y" :class="{'card-shadow-modifier': stageIndexProp}">
      <div class="card-content pt-1" :class="{'px-3': stageIndexProp, 'px-0': stageIndexProp}">
        <CardNavigation v-if="!stageIndexProp"/>
        <div class="container has-text-centered">
          <div class="notranslate is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color mb-5">
            {{ challenge.name }}
          </div>
          <b>This {{ $store.state.uiMods.task.toLowerCase() }} has a timer.</b><br><br>
          <div v-if="challenge.hint && challenge.hint.reveal_after_x_mins && !adventureTeam.uiMods.hideTimerNotices">
            There will be a {{ $store.state.uiMods.clue.toLowerCase() }} revealed after {{ challenge.hint.reveal_after_x_mins }} minute{{ challenge.hint.reveal_after_x_mins === 1 ? '' : 's' }}.
          </div>
          <div v-if="challenge.skippable_after_x_mins && !adventureTeam.uiMods.hideTimerNotices">
            You can skip this {{ $store.state.uiMods.task.toLowerCase() }} after {{ challenge.skippable_after_x_mins }} minute{{ challenge.skippable_after_x_mins === 1 ? '' : 's' }}.
          </div>
          <div v-if="challenge.lose_x_points_per_min && !adventureTeam.uiMods.hideTimerNotices">
            For every {{ formatTime(challenge.lose_x_points_per_min && challenge.lose_x_points_per_x_secs ? challenge.lose_x_points_per_x_secs : 60) }} you take to complete,
            you lose {{ challenge.lose_x_points_per_min }} point{{ challenge.lose_x_points_per_min === 1 ? '' : 's' }}, up to a maximum of {{ challenge.points }} {{ $store.state.uiMods.point.toLowerCase()}}s.
          </div>
        </div>
        <div @click="startChallengeTimer()"
        class="button is-primary-colors has-text-weight-semibold is-fullwidth mt-5"
        v-bind:class="{'is-light is-loading': loading}"
        >
          <i class="fas fa-play-circle mr-2"></i>
          Start {{ $store.state.uiMods.task.toLowerCase() }}
        </div>
      </div>
    </div>
    <div v-else-if="!showChallengeTimer" class="card overflow-y challenge-container" :class="{'card-shadow-modifier': stageIndexProp}">
      <div class="card-content pt-1" :class="{'px-3': stageIndexProp, 'px-0': stageIndexProp}">
        <CardNavigation v-if="!stageIndexProp"/>
        <ViewsStat :pageDetails="{pageName: 'challenge', stage: stageIndex, challenge: challengeIndex}"/>
        <div v-if="challenge">
          <div v-if="!challenge.completed && maxedSessionCompletions" class="container has-text-centered my-2">
            <span class="tag is-light">
            You are too late... too many teams have completed this {{ $store.state.uiMods.task.toLowerCase() }}.
            </span>
          </div>
          <div v-if="['judged-text', 'judged-image', 'judged-video', 'judged-file'] && latestAnswerLogEntry && !latestAnswerLogEntry.isPendingJudgement && !latestAnswerLogEntry.isValidAnswer && !latestAnswerLogEntry.isSkipped" class="container has-text-centered my-2">
            <span class="tag is-light">
            Previous answer marked as wrong
            </span>
          </div>
          <div v-if="!showSingleChallengeView">
            <div v-if="timeLimitText && adventureTeam.stage === currentStage" :key="timeLimitText" class="container has-text-centered">
              <div class="is-size-6 has-text-weight-semibold has-text-grey">
                <i class="fas fa-fw fa-stopwatch"></i> <b>{{ timeLimitText }}</b>
              </div>
            </div>
            <!-- <div v-if="timeLimitText && adventureTeam.stage === currentStage && stageDetails.auto_forward_when_time_is_up" class="has-text-centered">
              <div class="is-size-7 has-text-weight-semibold has-text-grey">
                <i class="fas fa-forward"></i>
                Pushed to next {{ $store.state.uiMods.chapter.toLowerCase() }} when time is up
              </div>
            </div> -->
          </div>
          <div v-if="showTaskMap" class="container mt-3">
            <task-map :challenge="challenge" :mapHeight="250" />
            <hr>
          </div>
          <div class="mb-4">
            <div v-if="!stageIndexProp" class="container has-text-centered">
              <div class="notranslate is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
                {{ challenge.name }}
              </div>
            </div>
            <div v-if="challengeTimerText && !challenge.completed" :key="challengeTimerText" class="container has-text-centered">
              <div class="is-size-6 has-text-weight-semibold has-text-grey">
                <i class="fas fa-fw fa-stopwatch"></i> <b>{{ challengeTimerText }}</b>
              </div>
            </div>
            <div v-if="challenge.hint && challenge.hint.reveal_after_x_mins && !adventureTeam.uiMods.hideTimerNotices" class="container has-text-centered mb-0 is-size-7 has-text-grey">
              There will be a {{ $store.state.uiMods.clue.toLowerCase() }} revealed after {{ challenge.hint.reveal_after_x_mins }} minute{{ challenge.hint.reveal_after_x_mins === 1 ? '' : 's' }}
            </div>
            <div v-if="challenge.skippable_after_x_mins && !adventureTeam.uiMods.hideTimerNotices" class="container has-text-centered mb-0 is-size-7 has-text-grey">
              You can skip this {{ $store.state.uiMods.task.toLowerCase() }} after {{ challenge.skippable_after_x_mins }} minute{{ challenge.skippable_after_x_mins === 1 ? '' : 's' }}
            </div>
            <div v-if="challenge.lose_x_points_per_min && !adventureTeam.uiMods.hideTimerNotices" class="container has-text-centered mb-0 is-size-7 has-text-grey">
              For every {{ formatTime(challenge.lose_x_points_per_min && challenge.lose_x_points_per_x_secs ? challenge.lose_x_points_per_x_secs : 60) }} you take to complete,
              you lose {{ challenge.lose_x_points_per_min }} point{{ challenge.lose_x_points_per_min === 1 ? '' : 's' }}, up to a maximum of {{ challenge.points }} {{ $store.state.uiMods.point.toLowerCase()}}s
            </div>
            <div v-if="challenge.limit_to_x_tries && (challenge.redoTimestamp || !challenge.completed) && !adventureTeam.uiMods.hideTimerNotices" :key="challengeTimerText" class="container has-text-centered">
              <div class="is-size-7 has-text-grey">
                {{ challenge.limit_to_x_tries - challenge.answerLog.length }} attempt{{ challenge.limit_to_x_tries - challenge.answerLog.length > 1 ? 's' : '' }} left
              </div>
            </div>
          </div>
          <div class="notranslate mt-5" v-if="challenge.briefing && challenge.briefing.length > 0" v-markdown>{{ challenge.briefing }}</div>
          <NypPassport v-if="challenge.briefing && challenge.briefing.includes('nyp-detector-passport')" :teamCode="$store.state.teamCode"/>
          <NypPassport v-if="challenge.briefing && challenge.briefing.includes('nyp-detector-profile-photo')" :teamCode="$store.state.teamCode" :teamName="$store.state.teamName" :isProfilePhotoOnly="true"/>
        </div>
        <div v-else class="container has-text-centered">
          <div v-if="!stageIndexProp" class="notranslate pt-4 is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            {{ challenge.name }}
          </div>
        </div>
        <div v-if="['judged-text', 'judged-image', 'judged-video', 'judged-file'] && latestAnswerLogEntry && latestAnswerLogEntry.comments && !latestAnswerLogEntry.isValidAnswer" class="container has-text-centered my-3 is-size-5">
          "{{ latestAnswerLogEntry.comments }}" - Facilitator
        </div>
      </div>
    </div>
    <div v-if="!showChallengeTimer" class="card overflow-y has-rounded-bottom-corners mb-5" :class="{'card-shadow-modifier': stageIndexProp}">
      <div v-if="showAnswerBox" class="card-content px-4 pt-4">
        <div v-if="challenge.type === 'direction' && !challenge.completed">
          <task-map :challenge="challenge" :mapHeight="240" />
        </div>
        <div class="container" v-if="disableAnswerSubmission">
          <div class="columns py-0 my-0 is-mobile is-vcentered is-gapless">
            <div class="column has-text-centered">
              <i class="fas fa-hourglass-half mr-2"></i>
              Time penalty! Submission disabled for {{ timePenaltyText }}
            </div>
          </div>
        </div>
        <div class="container" v-if="(!latestAnswerLogEntry || latestAnswerLogEntry && !latestAnswerLogEntry.isMaxTries) && challenge.redoTimestamp && (new Date()).getTime() < (new Date(challenge.redoTimestamp))">
          <div class="columns py-0 my-0 is-mobile is-vcentered is-gapless">
            <div @click="reload()" class="column has-text-centered is-clickable">
              Task can be re-attempted after {{ (new Date(challenge.redoTimestamp)).toLocaleString() }}. Once time has passed, <u>click here</u>.
            </div>
          </div>
        </div>
        <div class="container" v-if="!challenge.completed && adventureTeam.isActive === false">
          <div class="columns py-0 my-0 is-mobile is-vcentered is-gapless">
            <div class="column has-text-centered">
              No submissions allowed, {{ $store.state.uiMods.game.toLowerCase() }} is currently inactive.
            </div>
          </div>
        </div>
        <div class="container" v-if="allowSubmitAnswer && !disableAnswerSubmission">
          <div class="container has-text-centered has-text-danger" v-if="this.$store.state.userName === 'TESTING' && challenge.type === 'location'">
            For testing purposes, submitted location will always be marked as correct. <br>
            This message will not be shown to non-testing players.
          </div>
          <div
          class="container has-text-centered"
          v-if="this.challenge.time_restricted && (this.challenge.time_restricted_allow_after || this.challenge.time_restricted_allow_before || this.challenge.time_restricted_allow_after_date || this.challenge.time_restricted_allow_before_date)"
          >
            This {{ $store.state.uiMods.task.toLowerCase() }} is time-restricted to {{ generateTimeRestrictedString() }}
          </div>
          <nav class="level is-mobile mb-1">
            <div v-if="challenge.hint && ((challenge.hint.credits && !challenge.hint.hint) || challenge.hint.hint) && !(challenge.hint.reveal_after_x_tries && challenge.answerLog.length >= challenge.hint.reveal_after_x_tries)" class="level-item has-text-centered">
              <div @click="toggleHintScreen()"
              class="button is-primary is-light has-text-weight-semibold is-fullwidth mt-2 white-space-normal"
              >
                <i class="fas fa-lightbulb mr-2"></i>
                {{ challenge.hint.hint ? $store.state.uiMods.showClue : $store.state.uiMods.buyClue }}
              </div>
            </div>
            <div v-else-if="challenge.hint && !challenge.hint.hint && ((!challenge.hint.reveal_after_x_tries && !challenge.hint.reveal_after_x_mins) || (challenge.hint.reveal_after_x_tries != null && challenge.answerLog.length >= challenge.hint.reveal_after_x_tries))" class="level-item has-text-centered">
              <div @click="buyHint(true)"
              class="button is-primary is-light has-text-weight-semibold is-fullwidth mt-2 white-space-normal"
              >
                <i class="fas fa-lightbulb mr-2"></i>
                {{ $store.state.uiMods.showClue }}
                {{ challenge.hint.penalty ? `(-${challenge.hint.penalty} ${challenge.hint.penalty === 1 ? $store.state.uiMods.point.toLowerCase() : $store.state.uiMods.point.toLowerCase() + 's'})` : ''}}
              </div>
            </div>
            <div v-if="skippable" class="level-item has-text-centered">
              <div @click="toggleSkipScreen()"
              class="button is-primary is-light has-text-weight-semibold is-fullwidth mt-2 white-space-normal"
              >
                <i class="fas fa-forward mr-2"></i>
                Skip {{ $store.state.uiMods.task.toLowerCase() }}
              </div>
            </div>
          </nav>
          <div class="columns py-0 my-0 is-mobile is-vcentered is-gapless">
            <div
              v-show="!showHintScreen && !showResultScreen && !showSkipScreen"
              class="column"
            >
              <FormInput
              v-if="getTaskTypeConfig(challenge.type, 'inputType') === 'formInput'"
              :size="'medium'"
              :title="''"
              :type="challenge.type"
              :value="answer"
              v-on:update="answer = $event"
              v-on:valueScanned="valueScanned($event)"
              v-on:scrollToBottom="scrollToBottom()"
              />
              <MultiFormInput
              v-else-if="challenge.type === 'image-multiple'"
              :size="'medium'"
              :title="''"
              :value="answer"
              v-on:update="answer = $event"
              />
              <FillBlanksInput
              v-else-if="challenge.type === 'fill-in-the-blanks'"
              v-show="challenge.type === 'fill-in-the-blanks'"
              :text="challenge.answers[0].value"
              v-on:update="answer = $event"
              />
              <WordSearchInput
              v-else-if="challenge.type === 'word-search'"
              :words="challenge.answers"
              v-on:wordSearchSolved="submitAnswer"
              />
              <MatchingPairsInput
              v-else-if="challenge.type === 'matching-pairs'"
              :pairs="challenge.answers.map(x => [x.value, x.value1])"
              v-on:update="answer = $event"
              />
              <ScrambledPhraseInput
              v-else-if="challenge.type === 'scrambled-phrase'"
              :text="challenge.answers[0].value"
              v-on:update="answer = $event"
              />
              <ImageJigsawInput
              v-else-if="challenge.type === 'image-jigsaw'"
              :imageUrl="challenge.answers[0].value"
              :noOfXPieces="challenge.answers[0].noOfXPieces"
              :noOfYPieces="challenge.answers[0].noOfYPieces"
              v-on:imageJigsawSolved="submitAnswer"
              />
              <CombinationLockInput
              v-else-if="challenge.type === 'combination-lock'"
              :config="challenge.answers[0].config"
              v-on:update="answer = $event"
              />
            </div>
            <div v-if="getTaskTypeConfig(challenge.type, 'submitButtonPosition') === 'right'" class="column is-1 ml-1">
              <div @click="!answer ? null : submitAnswer()"
              class="button is-medium is-primary-colors has-text-weight-semibold is-fullwidth"
              v-bind:class="{'is-light is-loading': loading}"
              :disabled="!answer"
              >
                <i class="fas fa-paper-plane pointer"></i>
              </div>
            </div>
          </div>
          <div v-if="['multiple-choice', 'free-multiple-choice'].includes(challenge.type)" class="container">
            <div
            v-for="(answer, index) in multipleChoiceOptions"
            :key="index" @click="selectChoice(answer.answerIndex)"
            class="notranslate button is-fullwidth mb-1 is-light is-primary overflow-button"
            >
              <i v-if="choiceIndexes.includes(answer.answerIndex)" class="fas fa-check pointer mr-2"></i>
              {{ answer.value }}
            </div>
            <div @click="choiceIndexes.length === 0 ? null : submitAnswer()"
            class="button is-primary-colors has-text-weight-semibold is-fullwidth mt-4"
            v-bind:class="{'is-light is-loading': loading}"
            :disabled="choiceIndexes.length === 0"
            >
              <i class="fas fa-paper-plane pointer mr-2"></i>
              Submit
            </div>
          </div>
          <div v-if="loading && getTaskTypeConfig(challenge.type, 'showProgressBar')" class="container mt-1">
            <progress class="progress is-medium is-warning" :value="loadingValue" max="100"></progress>
          </div>

          <div
          v-if="challenge.type === 'direction' && !challenge.completed && getTaskTypeConfig(challenge.type, 'submitButtonPosition') === 'bottom'"
          class="buttons is-justify-content-center">
            <div

            @click="openGoogleMapsDirections(challenge.answers[0].lat, challenge.answers[0].lon)"
            class="button is-grey is-light has-text-weight-semibold"
            >
              <i class="fas fa-directions mr-2"></i>
              Get directions
            </div>
            <div
            @click="submitAnswer()"
            class="button is-primary-colors has-text-weight-semibold"
            v-bind:class="{'is-light is-loading': loading}"
            :disabled="!answer && !['direction'].includes(challenge.type)"
            >
            <i class="fas fa-paper-plane pointer mr-2"></i>
            {{
              challenge.type !== 'no-answer' ? (!getTaskTypeConfig(challenge.type, 'submitButtonText') ? 'Submit' :
              getTaskTypeConfig(challenge.type, 'submitButtonText')) : challenge.answers.length > 0 && challenge.answers[0].buttonText ? challenge.answers[0].buttonText :
              getTaskTypeConfig(challenge.type, 'submitButtonText')
            }}
            </div>
          </div>
          <div v-else-if="getTaskTypeConfig(challenge.type, 'submitButtonPosition') === 'bottom'" class="container">
            <div @click="submitAnswer()"
            class="button is-primary-colors has-text-weight-semibold is-fullwidth mt-1"
            v-bind:class="{'is-light is-loading': loading}"
            :disabled="!answer && !['no-answer'].includes(challenge.type)"
            >
            <i class="fas fa-paper-plane pointer mr-2"></i>
            {{
              challenge.type !== 'no-answer' ? (!getTaskTypeConfig(challenge.type, 'submitButtonText') ? 'Submit' :
              getTaskTypeConfig(challenge.type, 'submitButtonText')) : challenge.answers.length > 0 && challenge.answers[0].buttonText ? challenge.answers[0].buttonText :
              getTaskTypeConfig(challenge.type, 'submitButtonText')
            }}
            </div>
          </div>
          <code-input v-if="['code-input'].includes(challenge.type)" />
          <div
          v-if="challenge.type.includes('-share')"
          class="container mt-1 is-size-7 has-text-grey  has-text-centered">
            Submission will be shared with everyone in the session
          </div>

        </div>
        <div v-if="(!allowSubmitAnswer && challenge.completed && !showDrumRollScreen) || latestAnswerLogEntry && latestAnswerLogEntry.isPendingJudgement">
          <div v-if="challenge.type.includes('judged-') && latestAnswerLogEntry.isPendingJudgement && !latestAnswerLogEntry.isValidAnswer">
            <div class="container has-text-weight-semibold has-text-centered mb-3">
              <i class="spinning fas fa-circle-notch pointer mr-1"></i>
              Waiting...
            </div>
            <div v-if="['judged-text'].includes(challenge.type) && latestAnswerLogEntry.answerObj" class="container is-size-6">
              {{ latestAnswerLogEntry.answerObj.value }}
            </div>
            <div id="preview-judged-image-answer" v-if="['judged-image'].includes(challenge.type) && latestAnswerLogEntry.answerObj" class="box has-text-centered mb-2">
              <img class="" :src="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.value">
            </div>
            <div v-if="['judged-video'].includes(challenge.type) && latestAnswerLogEntry.answerObj" class="box has-text-centered mb-2">
              <video width="320" height="240" controls>
                <source :src="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.value" type="video/mp4">
                <source :src="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.value" type="video/ogg">
                Your browser does not support the video tag.
              </video>
            </div>
            <div v-if="['judged-file'].includes(challenge.type) && latestAnswerLogEntry.answerObj" class="is-flex is-flex-direction-column is-align-items-center mb-3">
                <div class="has-text-centered is-size-7" v-if="latestAnswerLogEntry.answerObj.filename">
                  {{ latestAnswerLogEntry.answerObj.filename }}
                </div>
                <div @click="openTab(latestAnswerLogEntry.answerObj.value)" class="button is-small is-rounded is-primary">
                  <i class="fas fa-external-link-alt mr-2"></i>
                  Open file
                </div>
              </div>
            <div v-if="!stageIndexProp" class="container has-text-centered">
              <div @click="$router.push({ name: 'Stage' })" class="button is-rounded is-primary glowing mt-3">
                <i class="fas fa-clipboard mr-2"></i>
                Return to {{ $store.state.uiMods.task.toLowerCase() }}s page
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="getTaskTypeConfig(this.challenge.type, 'showAnswer') && (!challenge.hide_answer_if_not_answered_correctly || challenge.hide_answer_if_not_answered_correctly && latestAnswerLogEntry.isValidAnswer)" class="pt-3">
              <div v-if="['text', 'number'].includes(getTaskTypeConfig(challenge.type, 'dataType'))">
                <div class="container has-text-weight-semibold has-text-centered is-size-3">
                  {{ latestAnswerLogEntry && latestAnswerLogEntry.answerObj.value }}
                </div>
              </div>
              <div v-if="['word-search'].includes(challenge.type)">
                <div class="container has-text-weight-semibold has-text-centered is-size-3">
                  {{ challenge.answers.map(x => x.value).join(', ') }}
                </div>
              </div>
              <div v-if="['matching-pairs'].includes(challenge.type)">
                <MatchingPairsInput
                :pairs="challenge.answers.map(x => [x.value, x.value1])"
                v-on:update="answer = $event"
                :answer="true"
                />
              </div>
              <div v-if="getTaskTypeConfig(challenge.type, 'dataType') === 'video' && latestAnswerLogEntry.answerObj" class="box has-text-centered mb-2">
                <video width="320" height="240" controls>
                  <source :src="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.value" type="video/mp4">
                  <source :src="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.value" type="video/ogg">
                  Your browser does not support the video tag.
                </video>
              </div>
              <div v-if="['multiple-choice', 'free-multiple-choice'].includes(challenge.type)">
                <div
                v-for="(answer, index) in challenge.answers"
                :key="index"
                >
                  <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.choiceIndexes.includes(index)" class="notranslate button is-fullwidth mb-1 is-light is-primary overflow-button">
                    <i class="fas fa-check mr-2"></i>
                    {{ answer.value }}
                  </div>
                </div>
              </div>
              <!-- <div v-if="['judged-text'].includes(challenge.type) && latestAnswerLogEntry.answerObj && !latestAnswerLogEntry.isSkipped" class="container is-size-6 mt-2">
                {{ latestAnswerLogEntry.answerObj.value }}
              </div> -->
              <div v-if="getTaskTypeConfig(challenge.type, 'dataType') === 'image' && latestAnswerLogEntry.answerObj && !latestAnswerLogEntry.isSkipped" class="box has-text-centered mb-2">
                <img class="" :src="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.value">
              </div>
              <div v-if="getTaskTypeConfig(challenge.type, 'dataType') === 'images' && latestAnswerLogEntry.answerObj && !latestAnswerLogEntry.isSkipped" class="box has-text-centered mb-2">
                <img class="mb-1" v-for="(image, index) in latestAnswerLogEntry.answerObj.value" :src="image" :key="index">
              </div>
              <div v-if="challenge.type === 'image-jigsaw' && latestAnswerLogEntry.answerObj && !latestAnswerLogEntry.isSkipped" class="box has-text-centered mb-2">
                <img class="" :src="challenge.answers[0].value">
              </div>
              <div v-if="getTaskTypeConfig(challenge.type, 'dataType') === 'audio' && latestAnswerLogEntry.answerObj && !latestAnswerLogEntry.isSkipped" class="box mb-2">
                <audio
                  :src="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.value"
                  controls="controls"
                  style="width: 100%;"
                >
                  <source
                    style="display: block;margin-left: auto;margin-right: auto;"
                    type="audio/wav"
                  >
                </audio>
              </div>
              <div v-if="challenge.type === 'location'">
                <Map
                v-if="!showHintScreen && !showResultScreen && !showSkipScreen"
                :lat="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.lat" :lon="latestAnswerLogEntry && latestAnswerLogEntry.answerObj.lon"/>
              </div>
              <div v-if="challenge.type === 'direction'">
                <task-map :challenge="challenge" :mapHeight="240" />
              </div>
              <div v-if="getTaskTypeConfig(challenge.type, 'dataType') === 'file'" class="is-flex is-flex-direction-column is-align-items-center mb-3">
                <div class="has-text-centered is-size-7" v-if="latestAnswerLogEntry.answerObj.filename">
                  {{ latestAnswerLogEntry.answerObj.filename }}
                </div>
                <div @click="openTab(latestAnswerLogEntry.answerObj.value)" class="button is-small is-rounded is-primary">
                  <i class="fas fa-external-link-alt mr-2"></i>
                  Open file
                </div>
              </div>
            </div>
            <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.comments" class="container has-text-centered mb-3 is-size-5">
              "{{ latestAnswerLogEntry.comments }}" - Facilitator
            </div>
            <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.isSkipped" class="container has-text-centered is-size-6">
              Skipped
            </div>
            <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.isTimeExceeded" class="container has-text-centered is-size-6">
              Exceeded Time
            </div>
            <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.points !== challengeStagePoints" class="container has-text-centered is-size-6">
              Gained {{ latestAnswerLogEntry.points ? latestAnswerLogEntry.points : 0 }} {{ $store.state.uiMods.point.toLowerCase() }}{{ latestAnswerLogEntry.points === 1 ? "" : "s" }} (in leaderboard)
            </div>
            <div v-if="challengeStagePoints && !$store.state.uiMods.hidePointsStarsWording" class="container has-text-centered is-size-6">
              Gained {{ challengeStagePoints ? challengeStagePoints : 0 }} {{ $store.state.uiMods.point.toLowerCase() }}{{ challengeStagePoints === 1 ? "" : "s" }} {{ challenge.type.includes('judged-') && latestAnswerLogEntry.points !== challengeStagePoints ? `(for this ${$store.state.uiMods.chapter.toLowerCase()})` : '' }}
            </div>
            <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.customCompletionMessage && (!challenge.hide_answer_if_not_answered_correctly || challenge.hide_answer_if_not_answered_correctly && latestAnswerLogEntry.isValidAnswer)" class="container">
              <hr class="my-2"/>
              <div class="mb-5 notranslate" v-markdown>{{ latestAnswerLogEntry.customCompletionMessage }}</div>
            </div>
            <div v-if="!stageIndexProp" class="container has-text-centered">
              <div @click="$router.push({ name: 'Stage' })" class="button is-rounded is-primary glowing mt-3">
                <i class="fas fa-clipboard mr-2"></i>
                Return to {{ $store.state.uiMods.task.toLowerCase() }}s page
              </div>
            </div>
          </div>
        </div>
        <div v-if="!allowSubmitAnswer && !challenge.completed && latestAnswerLogEntry && !latestAnswerLogEntry.isPendingJudgement">
          <div class="container has-text-centered is-size-3">
              Not completed
            </div>
        </div>
        <CardNavigation v-if="!stageIndexProp"/>
      </div>
      <div v-else class="card-content mb-3 px-4 pt-4 has-text-centered">
        <i class="spinning fas fa-circle-notch pointer"></i>
      </div>
    </div>
    <transition name="fade">
      <div
      class="modal task-modal-modifier"
      v-if="showResultScreen"
      v-bind:class="{'is-active': showResultScreen}"
      >
        <div @click="toggleResultScreen()" class="modal-background pointer"></div>
        <div class="modal-content result-modal">
          <div class="card mx-4">
            <div v-if="showDrumRollScreen" class="card-content has-text-centered" :key="1">
              <div class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                {{ $store.state.uiMods.loadingText ? $store.state.uiMods.loadingText : t('Loading...') }}
              </div>
              <div class="container has-text-centered py-3">
                <img
                  v-if="$store.state.uiMods.loadingImageUrl"
                  :src="$store.state.uiMods.loadingImageUrl"
                  style="width:100%; max-width:200px;"
                >
                <img
                  v-else
                  src="../../public/img/drumroll.gif"
                  style="width:100%; max-width:200px;"
                >
              </div>
            </div>
            <div v-if="!showDrumRollScreen && latestAnswerLogEntry && (latestAnswerLogEntry.isValidAnswer || latestAnswerLogEntry.isSkipped)" class="card-content" :key="2">
              <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.isMaxTries && !latestAnswerLogEntry.isValidAnswer" class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                Incorrect, no more attempts allowed
              </div>
              <div v-else-if="latestAnswerLogEntry && latestAnswerLogEntry.isSkipped && this.adventureTeam.uiMods.challengeSkippedText" class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                {{ this.adventureTeam.uiMods.challengeSkippedText }}
              </div>
              <div v-else-if="latestAnswerLogEntry && latestAnswerLogEntry.isSkipped" class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                {{ $store.state.uiMods.task }} skipped
              </div>
              <div v-else-if="this.adventureTeam.uiMods.challengeCompletedText" class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                {{ this.adventureTeam.uiMods.challengeCompletedText }}
              </div>
              <div v-else class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                {{ $store.state.uiMods.task }} completed
              </div>
              <div v-if="challenge.type === 'object-recognition' && latestAnswerLogEntry.answerObj.detectedObjects" class="container has-text-centered mb-4">
                {{
                  latestAnswerLogEntry.answerObj.detectedObjects.length > 0 ?
                  `Objects detected: ${latestAnswerLogEntry.answerObj.detectedObjects.map(detectedObject => detectedObject.description).join(', ')}` :
                  'No recognisable object detected.'
                }}
              </div>
              <div v-if="challengeStagePoints && !$store.state.uiMods.hidePointsStarsWording" class="container has-text-centered mb-4">
                You have gained {{ challengeStagePoints ? challengeStagePoints : 0 }} {{ $store.state.uiMods.point.toLowerCase() }}{{ challengeStagePoints === 1 ? "": "s" }}.
              </div>
              <div v-if="!adventureTeam.hideLeaderboard && latestAnswerLogEntry.isTimeExceeded && !latestAnswerLogEntry.isSkipped && !$store.state.uiMods.hidePointsStarsWording" class="container has-text-centered mb-4">
                However, no {{ $store.state.uiMods.point.toLowerCase()}}s added to leaderboard as the time limit has been exceeded.
              </div>
              <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.isMaxTries && !latestAnswerLogEntry.isValidAnswer && !$store.state.uiMods.hidePointsStarsWording" class="container has-text-centered mb-4">
                However, no {{ $store.state.uiMods.point.toLowerCase()}}s added to leaderboard as the number of attempts have exceeded.
              </div>
              <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.isSkipped && latestAnswerLogEntry.points === 0 && !$store.state.uiMods.hidePointsStarsWording" class="container has-text-centered mb-4">
                However, no {{ $store.state.uiMods.point.toLowerCase()}}s added to leaderboard for skipping this.
              </div>
              <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.isSkipped && latestAnswerLogEntry.points < 0 && !$store.state.uiMods.hidePointsStarsWording" class="container has-text-centered mb-4">
                <b>{{ -latestAnswerLogEntry.points }} {{ $store.state.uiMods.point.toLowerCase()}}s</b> deducted from the leaderboard for skipping this {{ $store.state.uiMods.task.toLowerCase() }}.
              </div>
              <!-- <div @click="$router.push({ name: 'Inventory' })" v-if="latestAnswerLogEntry && latestAnswerLogEntry.hasItem" class="container has-text-centered mb-4 pointer">
                <b>Something found!</b> Go to your <i class="fas fa-sm fa-box ml-1"></i> Inventory to see it.
              </div> -->
              <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.customCompletionMessage && (!challenge.hide_answer_if_not_answered_correctly || challenge.hide_answer_if_not_answered_correctly && latestAnswerLogEntry.isValidAnswer)" class="container">
                <hr v-if="!$store.state.uiMods.hidePointsStarsWording" class="my-2"/>
                <div class="mb-5 notranslate" v-markdown>{{ latestAnswerLogEntry.customCompletionMessage }}</div>
              </div>
              <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.customFailureMessage && !latestAnswerLogEntry.isValidAnswer" class="container">
                <div class="mb-3 notranslate" v-markdown>{{ latestAnswerLogEntry.customFailureMessage }}</div>
              </div>
              <div
              v-if="!adventureTeam.uiMods.useBackToTaskListButton"
              @click="processNextTask()"
              class="button is-primary-colors has-text-weight-semibold is-fullwidth">
                <div v-if="!loading">
                  {{ t('Next') }}
                </div>
              </div>
              <button
              v-if="adventureTeam.uiMods.useBackToTaskListButton"
              @click="$router.push({ name: 'Stage' })"
              class="button is-primary-colors has-text-weight-semibold is-fullwidth">
                <i class="fas fa-clipboard mr-2"></i>
                Back to {{ $store.state.uiMods.task.toLowerCase() }}s page
              </button>
            </div>
            <div v-else-if="!showDrumRollScreen && latestAnswerLogEntry && !latestAnswerLogEntry.isValidAnswer && !latestAnswerLogEntry.isPendingJudgement" class="card-content">
              <div class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-4">
                {{ $store.state.uiMods.tryAgainText ? $store.state.uiMods.tryAgainText : 'Incorrect, try again' }}
              </div>
              <div v-if="challenge.type === 'object-recognition' && latestAnswerLogEntry.answerObj.detectedObjects" class="container has-text-centered mb-4">
                {{
                  latestAnswerLogEntry.answerObj.detectedObjects.length > 0 ?
                  `Objects detected: ${latestAnswerLogEntry.answerObj.detectedObjects.map(detectedObject => detectedObject.description).join(', ')}` :
                  'No recognisable object detected.'
                }}
              </div>
              <div v-if="challenge.type === 'judged-image-ai' && latestAnswerLogEntry.answerObj.explanation" class="container has-text-centered mb-4">
                {{ latestAnswerLogEntry.answerObj.explanation }}
                <div class="has-text-grey-lighter is-size-7 mt-2">Assessed by AI</div>
              </div>
              <div class="container has-text-centered">
              </div>
              <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.points < 0 && !adventureTeam.hideLeaderboard && !$store.state.uiMods.hidePointsStarsWording" class="container has-text-centered">
                <b>{{ -latestAnswerLogEntry.points }}</b> {{ $store.state.uiMods.point.toLowerCase() }}{{-latestAnswerLogEntry.points === 1 ? '' : 's'}} will be deducted from the leaderboard.
              </div>
              <div v-if="latestAnswerLogEntry && latestAnswerLogEntry.customFailureMessage" class="container">
                <div class="mb-3 notranslate" v-markdown>{{ latestAnswerLogEntry.customFailureMessage }}</div>
              </div>
              <div @click="toggleResultScreen()" class="button is-primary-colors has-text-weight-semibold is-fullwidth mt-2">
                {{ t('Close') }}
              </div>
            </div>
            <div v-if="!showDrumRollScreen && latestAnswerLogEntry && !latestAnswerLogEntry.isValidAnswer && latestAnswerLogEntry.isPendingJudgement" class="card-content">
              <div v-if="!challenge.custom_judgement_message" class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                {{$store.state.uiMods.waitForFaciHeader ? $store.state.uiMods.waitForFaciHeader : `Sent for ${this.$store.state.uiMods.game.toLowerCase()} ${this.$store.state.uiMods.facilitator.toLowerCase()}'s assessment!` }}
              </div>
              <!-- <div class="container has-text-centered">
                <svg width="165" height="202" viewBox="0 0 165 202" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M69.9195 132.508L54.4741 189.297" stroke="#FD8369" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M75.5662 132.508L91.0116 189.297" stroke="#FD8369" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M64.2728 133.67H34.7106L0 51.0604H29.4791L64.2728 133.67Z" fill="#FD8369"/>
                <path d="M74.2375 89.6671V172.277" stroke="#FD8369" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M85.3647 89.6671V184.98" stroke="#FD8369" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M102.793 182.73L98.8901 182.751L98.9135 187.069L102.816 187.048L102.793 182.73Z" fill="#DE8E68"/>
                <path d="M98.8174 190.127L113.266 190.792L113.515 189.546L103.551 185.976L98.9004 186.142L98.8174 190.127Z" fill="black"/>
                <path d="M78.6892 182.789L75.6287 187.533L79.8856 190.278L82.9462 185.534L78.6892 182.789Z" fill="#DE8E68"/>
                <path d="M98.7344 171.779V183.153H102.554C102.554 183.153 109.114 167.711 111.19 157.499" fill="#56CAD8"/>
                <path d="M62.7781 100.543C65.1862 100.045 80.1334 97.5545 96.4922 97.5545C115.342 97.5545 123.397 99.7131 125.307 101.623C127.217 103.532 119.826 132.508 118.747 134.75" fill="#56CAD8"/>
                <path d="M30.8908 96.2261C29.5622 98.7168 29.8943 99.215 29.5622 107.6C28.2336 137.988 71.7464 133.255 101.059 141.392C93.5858 164.805 78.3896 181.991 78.3896 183.153C78.3896 184.316 81.8772 186.889 82.4585 186.557C94.001 180.663 129.708 141.475 129.21 134.833C128.296 122.379 69.7534 100.377 62.0307 100.377" fill="#74D5DE"/>
                <path d="M76.5627 62.3518C83.2889 68.0806 87.1918 80.2852 87.607 80.2852C104.215 80.2852 120.076 84.6025 121.238 84.6025C122.401 84.6025 124.643 83.0251 125.556 83.0251C126.47 83.0251 130.539 84.8516 130.539 85.7649C130.539 86.6782 81.9603 86.014 80.7977 86.014C79.8012 85.0177 70.999 76.7152 70.999 75.3868" fill="#D9805A"/>
                <path d="M120.989 58.2836L124.809 71.0695" stroke="#FD8369" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M54.8892 39.9351C50.239 43.5051 55.3044 69.2429 59.6225 70.8204C63.9405 72.3979 78.4725 62.85 78.7216 59.4459C78.9707 56.0419 59.7055 36.282 54.8892 39.9351Z" fill="#FED07D"/>
                <path d="M30.2264 88.7538C35.3749 66.254 53.1454 33.7912 56.9652 30.2211C64.937 30.2211 75.5661 48.1545 75.5661 58.7818C75.5661 70.8204 64.4388 99.464 62.1967 100.958C58.1278 103.698 35.8731 101.623 29.9773 96.2261C28.8978 95.2298 30.2264 88.7538 30.2264 88.7538Z" fill="#FED385"/>
                <path d="M57.7957 21.5035C59.2174 21.5035 60.3699 20.3512 60.3699 18.9297C60.3699 17.5083 59.2174 16.3559 57.7957 16.3559C56.374 16.3559 55.2214 17.5083 55.2214 18.9297C55.2214 20.3512 56.374 21.5035 57.7957 21.5035Z" fill="#DE8E68"/>
                <path d="M55.6366 32.4628C55.4705 33.044 63.0271 34.6215 63.6084 34.0403C63.6084 32.8779 63.6084 27.8964 64.2727 26.0699C64.937 24.2433 59.9546 18.9297 58.4599 18.4316C56.9652 20.8393 53.3945 17.4353 54.7231 20.8393C56.0518 24.2433 56.9652 27.8964 55.6366 32.4628Z" fill="#D9805A"/>
                <path d="M62.8611 28.1455C66.3924 28.1455 69.2552 22.7556 69.2552 16.1069C69.2552 9.45811 66.3924 4.06823 62.8611 4.06823C59.3298 4.06823 56.467 9.45811 56.467 16.1069C56.467 22.7556 59.3298 28.1455 62.8611 28.1455Z" fill="#DE8E68"/>
                <path d="M51.4846 6.14386C44.6753 6.14386 43.3467 0 35.3749 0C28.8147 0 26.4066 4.40033 23.5832 6.55898C20.8429 8.63461 10.629 11.1254 10.629 22.2507C10.629 29.972 16.5248 37.0292 23.085 37.0292C31.2229 37.0292 39.1947 26.8171 47.8308 26.8171C51.4846 26.8171 50.8203 28.1455 55.0553 28.1455C56.6331 28.1455 56.633 26.9831 56.633 26.568C56.633 26.1529 57.2974 25.2396 57.2974 24.3263C57.2974 21.5865 55.0553 22.7489 55.0553 19.0958C55.0553 17.5183 56.6331 16.439 58.2108 16.439C59.7886 16.439 66.5978 10.7102 66.5978 6.64201C67.013 7.05713 67.7604 9.13276 67.7604 9.38183C68.0095 9.38183 68.1756 8.21948 68.1756 7.80436C68.1756 3.23798 63.6084 2.15865 62.0306 2.15865C58.543 2.07563 56.9652 6.14386 51.4846 6.14386Z" fill="black"/>
                <path d="M54.4741 42.924C49.8239 46.494 54.8893 72.2318 59.2074 73.8093C63.5255 75.3868 78.0574 65.8389 78.3066 62.4348C78.5557 59.0308 59.2074 39.2709 54.4741 42.924Z" fill="#FED892"/>
                <path d="M76.0644 64.6765C76.7287 65.5898 80.7977 71.2355 80.7977 71.2355C83.0398 71.2355 91.2607 70.8204 118.664 62.85C119.577 62.6009 121.736 66.4201 120.242 67.4164C115.259 70.5713 81.2129 85.7649 73.7393 85.7649C71.9124 85.7649 62.1968 73.7263 62.1968 72.1488" fill="#DE8E68"/>
                <path d="M121.736 67.6654C123.571 67.6654 125.058 66.1786 125.058 64.3444C125.058 62.5103 123.571 61.0234 121.736 61.0234C119.902 61.0234 118.415 62.5103 118.415 64.3444C118.415 66.1786 119.902 67.6654 121.736 67.6654Z" fill="#DE8E68"/>
                <path d="M73.3242 190.875L88.7696 202L89.8491 200.838L81.4621 189.712L76.3136 186.557L73.3242 190.875Z" fill="black"/>
                <path d="M165 86.2631H69.9194V90.5804H165V86.2631Z" fill="#FD8369"/>
                <path d="M149.139 89.6671V172.277" stroke="#FD8369" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M160.433 89.6671V184.98" stroke="#FD8369" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M44.2602 132.508L28.8148 189.297" stroke="#FD8369" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
                </svg>
              </div> -->
              <div v-if="!challenge.custom_judgement_message" class="container has-text-weight-semibold has-text-centered mb-3">
                {{ $store.state.uiMods.waitForFaciText ? $store.state.uiMods.waitForFaciText : 'It might take some time. You can do other tasks while waiting.' }}
              </div>
              <div v-if="challenge.custom_judgement_message" class="mb-3 notranslate" v-markdown>{{ challenge.custom_judgement_message }}</div>
              <div @click="toggleResultScreen()" class="button is-rounded is-primary-colors has-text-weight-semibold is-fullwidth">
                Return to {{ $store.state.uiMods.task.toLowerCase() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
      class="modal hint-modal"
      v-if="showHintScreen"
      v-bind:class="{'is-active': showHintScreen}"
      >
        <div @click="toggleHintScreen()" class="modal-background pointer"></div>
        <div class="modal-content">
          <div class="card has-rounded-top-corders">
            <div v-if="!challenge.hint.hint" class="card-content" key="hint-1">
              <div class="container has-text-weight-semibold has-text-centered">
                <h3 class="title is-3 has-baskerville-font has-text-weight-bold has-text-brand-color mb-4">{{ $store.state.uiMods.buyClue }}</h3>
              </div>
              <div class="container mb-2">
                {{
                  getLang() !== 'de' ?
                  `Would you like to buy a ${$store.state.uiMods.clue.toLowerCase()} for this task? You have ${adventureTeam.credits} ${$store.state.uiMods.coin.toLowerCase()}s for this entire ${$store.state.uiMods.game.toLowerCase()}.`
                  :
                  t("Would you like to buy a hint")
                }}
              </div>
              <div
              v-if="adventureTeam.credits >= challenge.hint.credits"
              @click="buyHint(false)"
              :disabled="loading || challenge.hint.hint || adventureTeam.credits < challenge.hint.credits"
              class="button is-primary-colors has-text-weight-semibold is-fullwidth mb-1"
              v-bind:class="{'is-light is-loading': loading}"
              >
                {{
                  getLang() !== 'de' ?
                  `Yes, buy ${$store.state.uiMods.clue.toLowerCase()} for ${challenge.hint.credits} ${$store.state.uiMods.coin.toLowerCase()}s`
                  :
                  t("Buy hint")
                }}
              </div>
              <div
              v-if="adventureTeam.credits < challenge.hint.credits"
              disabled
              class="button is-primary-colors has-text-weight-semibold is-fullwidth mb-2">
                <div v-if="!loading">
                  Buy {{ $store.state.uiMods.clue.toLowerCase() }} for {{ challenge.hint.credits }} credits
                </div>
              </div>
              <div
              v-if="adventureTeam.credits < challenge.hint.credits"
              class="container mb-2"
              >
                Sorry, you don't have enough {{ $store.state.uiMods.coin.toLowerCase() }}s to buy a {{ $store.state.uiMods.clue.toLowerCase() }}.
              </div>
              <div
              @click="toggleHintScreen()"
              class="button is-light has-text-weight-semibold is-fullwidth">
              {{ t('Close') }}
              </div>
            </div>
            <div v-if="challenge.hint.hint" class="card-content" key="hint-2">
              <div class="container has-text-weight-semibold has-text-centered">
                <h3 class="title is-3 has-baskerville-font has-text-weight-bold has-text-brand-color mb-5">{{ $store.state.uiMods.clue }}</h3>
              </div>
              <div class="container">
                <div class="mb-5 notranslate" v-markdown>{{ challenge.hint.hint }}</div>
              </div>
              <div
              @click="toggleHintScreen()"
              class="button is-light has-text-weight-semibold is-fullwidth">
                {{ t('Close') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
      class="modal hint-modal"
      v-if="showSkipScreen"
      v-bind:class="{'is-active': showSkipScreen}"
      >
        <div @click="toggleSkipScreen()" class="modal-background pointer"></div>
        <div class="modal-content">
          <div class="card has-rounded-top-corders">
            <div v-if="!latestAnswerLogEntry || !latestAnswerLogEntry.isSkipped" class="card-content">
              <div class="container has-text-weight-semibold has-text-centered">
                <h3 class="title is-3 has-baskerville-font has-text-weight-bold has-text-brand-color mb-4">
                  Skip {{ $store.state.uiMods.task.toLowerCase() }}
                </h3>
              </div>
              <div class="container mb-2">
                <div v-if="adventureTeam.hideLeaderboard || !challenge.skip_penalty">
                  {{ t('Would you like to skip this task?') }}
                </div>
                <div v-else-if="challenge.skip_penalty > 0">
                  {{ t('Would you like to skip this task?') }} <b>{{ challenge.skip_penalty }} {{ $store.state.uiMods.point.toLowerCase()}}s</b> will be deducted.
                </div>
              </div>
              <div v-if="!$store.state.uiMods.hidePointsStarsWording && !challenge.no_stage_points_if_not_answered_correctly" class="container mb-4">
                <div>
                  {{ t('You will still get') }} {{ $store.state.uiMods.point.toLowerCase()}}s {{ t('to complete the chapter') }}{{!adventureTeam.hideLeaderboard ? t(', but no points will be added to the leaderboard.') : '.'}}
                </div>
              </div>
              <div
              @click="skipTask()"
              class="button is-primary-colors has-text-weight-semibold is-fullwidth"
              v-bind:class="{'is-light is-loading': loading}"
              :disabled="loading"
              >
                <div v-if="challenge.skip_penalty > 0">
                  Yes, skip {{ $store.state.uiMods.task.toLowerCase() }} and deduct {{ challenge.skip_penalty }} {{ $store.state.uiMods.point.toLowerCase()}}s.
                </div>
                <div v-else>
                  Yes, skip {{ $store.state.uiMods.task.toLowerCase() }}.
                </div>
              </div>
              <div
              @click="toggleSkipScreen()"
              class="button is-light has-text-weight-semibold is-fullwidth mt-1">
                {{ t('Close') }}
              </div>
            </div>
            <div v-if="latestAnswerLogEntry && !latestAnswerLogEntry.isSkipped" class="card-content">
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
      class="modal hint-modal"
      v-if="showOutsideLocationScreen"
      v-bind:class="{'is-active': showOutsideLocationScreen}"
      >
        <div @click="showOutsideLocationScreen = false" class="modal-background pointer"></div>
        <div class="modal-content">
          <div class="card has-rounded-top-corders">
            <div v-if="!latestAnswerLogEntry || !latestAnswerLogEntry.isSkipped" class="card-content">
              <div class="container has-text-weight-semibold has-text-centered">
                <h3 class="title is-3 has-baskerville-font has-text-weight-bold has-text-brand-color mb-4">
                  Too far...
                </h3>
              </div>
              <div class="container mb-2">
                <div>
                  You seem to be too far from the location. Close and click on "Update location" on the map when you are nearer.
                </div>
              </div>
              <div
              @click="showOutsideLocationScreen = false"
              class="button is-light has-text-weight-semibold is-fullwidth mt-1">
                {{ t('Close') }}
              </div>
            </div>
            <div v-if="latestAnswerLogEntry && !latestAnswerLogEntry.isSkipped" class="card-content">
            </div>
          </div>
        </div>
      </div>
    </transition>
    <NextStage v-if="!showResultScreen && showNextStageAlert" />
    <SelectTeamNameModal
    :noOfTeamsToSelect="this.challenge.must_complete_with_x_other_teams"
    v-if="showSelectTeamNameModal"
    v-on:submitAnswer="submitAnswer($event)"
    v-on:close="showSelectTeamNameModal = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CardNavigation from '@/components/CardNavigation'
import FormInput from '@/components/forms/FormInput'
import MultiFormInput from '@/components/forms/MultiFormInput'
import FillBlanksInput from '@/components/forms/FillBlanksInput'
import WordSearchInput from '@/components/forms/WordSearchInput'
import MatchingPairsInput from '@/components/forms/MatchingPairsInput'
import ScrambledPhraseInput from '@/components/forms/ScrambledPhraseInput'
import ImageJigsawInput from '@/components/forms/ImageJigsawInput'
import CombinationLockInput from '@/components/forms/CombinationLockInput'
import Map from '@/components/Map'
import firebaseApp from '@/firebase/init'
import ViewsStat from '@/components/ViewsStat.vue'
import CodeInput from '@/components/CodeInput.vue'
import NextStage from  '@/components/NextStage.vue'
import loadImage from 'blueimp-load-image'
import TaskMap from  '@/components/TaskMap.vue'
import { getTaskTypeConfig } from '../utils/taskTypes.ts'
import { compressImage, checkIfTeamPositionsInRadiusWithin10Mins } from '../utils/utils.ts'
import SelectTeamNameModal from '@/components/SelectTeamNameModal'
import NypPassport from '@/views/nyp/Passport.vue'

export default {
  name: 'Challenge',
  props: ['stageIndexProp', 'challengeIndexProp', 'showSingleChallengeView'],
  data() {
    return {
      loadPage: false,
      timeLimitText: null,
      challengeTimerText: null,
      challengeTimerInterval: null,
      challengeTimerSkippable: false,
      stageIndex: this.stageIndexProp ? this.stageIndexProp : parseInt(this.$route.params.stageIndex),
      challengeIndex: this.challengeIndexProp ? this.challengeIndexProp-1 : parseInt(this.$route.params.challengeIndex)-1,
      answer: null,
      choiceIndexes: [],
      downloadUrl: null,
      loading: false,
      loadingValue: 0,
      showResultScreen: false,
      showHintScreen: false,
      showSkipScreen: false,
      showDrumRollScreen: false,
      showOutsideLocationScreen: false,
      timePenaltyInterval: null,
      timePenaltyText: null,
      disableAnswerSubmission: false,
      showAnswerBox: false,
      getTaskTypeConfig,
      showSelectTeamNameModal: false
    }
  },
  components: {
    FormInput,
    MultiFormInput,
    FillBlanksInput,
    WordSearchInput,
    MatchingPairsInput,
    ScrambledPhraseInput,
    ImageJigsawInput,
    CombinationLockInput,
    Map,
    ViewsStat,
    CardNavigation,
    CodeInput,
    NextStage,
    TaskMap,
    SelectTeamNameModal,
    NypPassport
  },
  updated() {
    document.querySelectorAll('img').forEach(item => {
      if(
        !item.className.includes('leaflet') &&
        !item.className.includes('viewer-move') &&
        !item.className.includes('no-zoom')
      ){
        item.removeEventListener('click', this.showPhotoViewer)
        item.addEventListener('click', this.showPhotoViewer)
      }
    })
  },
  mounted() {
    if (
      this.latestAnswerLogEntry &&
      this.latestAnswerLogEntry.isPendingJudgement
    ) {
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
          methodName: 'check-judgement-status',
          teamCode: this.$store.state.teamCode,
          stageIndex: this.stageIndex - 1,
          challengeIndex: this.challengeIndex
      })
    }

    window.addEventListener('scroll', this.onScroll)

    this.processPowerupImmediateUse()

    let playerIndexTest = true
    if(
      this.challenge &&
      this.challenge.show_specific_players_only
    ){
      const playerIndex = this.adventureTeam.teamMembers.map(obj => obj.name).indexOf(this.$store.state.userName) + 1
      const shownIndexes = this.challenge.show_specific_players_only.split(',').map(str => parseInt(str))
      playerIndexTest =  shownIndexes.includes(playerIndex)
    }

    if(
      this.challenge &&
      (
        (
          (
            !this.challenge.completed &&
            this.challenge.position &&
            !this.challenge.position.visibleOutsideRadius &&
            !this.userLocation
          )
        ) ||
        (
          this.challenge.access_code &&
          this.latestAccessCode !== this.challenge.access_code
        ) ||
        (
          this.stageDetails.complete_challenges_in_order &&
          this.currentSortedChallengesIndex !== 0 &&
          !this.challenge.completed
        ) ||
        (
          this.challenge.hide_after_completion &&
          this.challenge.completed
        ) ||
        (
          this.challenge.show_only_after_x_minutes &&
          this.challenge.show_only_after_x_minutes >= (((Date.now() / 1000) - this.adventureTeam.stageStartedAt.seconds) / 60)
        ) ||
        (
          !this.checkIfWithinTimeRestrictions() &&
          this.challenge.time_restricted_hide_outside_timeframe &&
          !this.challenge.completed
        ) ||
        !playerIndexTest ||
        (
          this.adventureTeam.hiddenTaskCategories &&
          this.challenge.task_category &&
          this.adventureTeam.hiddenTaskCategories.includes(this.challenge.task_category)
        ) ||
        (
          this.challenge.show_only_after_x_chapter_points &&
          this.adventureTeam.stagePoints < this.challenge.show_only_after_x_chapter_points
        )
      ) &&
      !this.$store.state.userName.includes('TESTING')
    ) {
      this.$router.push({ name: 'Stage' })
    } else {
      this.loadPage = true
    }

    this.$store.dispatch('enterPage', {
      pageName: 'challenge',
      stage: this.stageIndex,
      challenge: this.challengeIndex
    })

    if (this.challenge) {
      this.checkTimePenalty()
      this.timePenaltyInterval = setInterval(() => {
        this.checkTimePenalty()
      }, 1000)
    }

    document.querySelectorAll('img').forEach(item => {
      if(
        !item.className.includes('leaflet') &&
        !item.className.includes('viewer-move') &&
        !item.className.includes('no-zoom')
      ){
        item.removeEventListener('click', this.showPhotoViewer)
        item.addEventListener('click', this.showPhotoViewer)
      }
    })

    setTimeout(() => {
      this.showAnswerBox = true
    }, 500)

    this.startRefresherForRedoTimestamp()

    if(
      this.challenge &&
      this.challenge.briefing.includes('iframe') &&
      (
        this.challenge.briefing.includes('accelerometer') ||
        this.challenge.briefing.includes('vr')
      )
    ){
      // getOrientationPermission()
    }
  },
  computed: {
    showOutOfVisiblityMap() {
      if (this.$store.state.userName?.includes('TESTING')) {
        return false
      }
      if(
        this.challenge &&
        !this.challenge.completed &&
        this.challenge.position &&
        !this.challenge.position.visibleOutsideRadius &&
        this.userLocation &&
        this.challenge.position.radius_in_meters < this.distanceBetweenTwoGeoCoordinatesInMeters(
          this.userLocation.lat, this.userLocation.lon, this.challenge.position.lat, this.challenge.position.lon
        ) &&
        !checkIfTeamPositionsInRadiusWithin10Mins(
          this.adventureTeam.teamPositions,
          this.adventureTeam.teamName,
          this.challenge.position,
          this.userLocation
        )
      ) {
        return true
      }
      return false
    },
    showChallengeTimer() {
      if (!this.challenge) return false
      return (
        this.challenge.lose_x_points_per_min ||
        (this.challenge.hint && this.challenge.hint.reveal_after_x_mins) ||
        (this.challenge.skippable && this.challenge.skippable_after_x_mins)
        )
        && !this.challenge.startedAt
    },
    showTaskMap() {
      if(this.challenge){
        return !!this.challenge.position && !this.challenge.completed
      }
      return false
    },
    challenge() {
      if(this.adventureTeam){
        if(this.adventureTeam.stage === this.stageIndex){
          return this.adventureTeam.stageDetails.challenges[this.challengeIndex]
        } else if (
          this.adventureTeam.stageDetailsHistory &&
          this.adventureTeam.stageDetailsHistory.filter(x => x.stageNumber === this.currentStage)[0]
        ) {
          return this.adventureTeam.stageDetailsHistory.filter(x => x.stageNumber === this.currentStage)[0].challenges[this.challengeIndex]
        }
        return null
      } else {
        return null
      }
    },
    stageDetails() {
      if(this.adventureTeam){
        if(this.adventureTeam.stage === this.currentStage){
          return this.adventureTeam.stageDetails
        } else {
          return this.adventureTeam.stageDetailsHistory.filter(x => x.stageNumber === this.currentStage)[0]
        }
      } else {
        return null
      }
    },
    latestAnswerLogEntry() {
      if(
        this.adventureTeam &&
        this.adventureTeam.answerLog &&
        this.adventureTeam.answerLog.length > 0
      ) {
        const relevantLogEntry = [...this.adventureTeam.answerLog].reverse().find(logEntry =>
          logEntry.stageIndex === this.stageIndex - 1 &&
          logEntry.challengeIndex === this.challengeIndex
        )
        return relevantLogEntry ? relevantLogEntry : null
      }
      return null
    },
    allowSubmitAnswer() {
      if (this.challenge.briefing?.includes('nyp-detector-passport')) {
        return false
      }
      if(this.challenge){
        if(
          (
            !this.challenge.completed ||
            (
              this.challenge.completed &&
              this.challenge.redoTimestamp &&
              (new Date()).getTime() > (new Date(this.challenge.redoTimestamp)) &&
              !this.latestAnswerLogEntry.isMaxTries
            )
          )
          && !this.adventureTeam.completedAt
          && this.adventureTeam.isActive !== false
          && this.adventureTeam.stage === this.stageIndex
          && !(
            this.latestAnswerLogEntry
            && this.latestAnswerLogEntry.isPendingJudgement
          )
          && !this.maxedSessionCompletions
          && !this.showOutOfVisiblityMap
          && (
            !this.challenge.limit_to_x_tries ||
            (this.challenge.limit_to_x_tries - this.challenge.answerLog.length > 0)
          ) // TODO: Do long term fix from backend instead!
        ){
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    maxedSessionCompletions(){
      if (
        this.challenge &&
        this.challenge.max_completions_per_session &&
        this.adventureTeam.challengeCompletions &&
        this.adventureTeam.challengeCompletions[this.currentStage] &&
        this.adventureTeam.challengeCompletions[this.currentStage][this.$route.params.challengeIndex-1]
      ) {
        return this.challenge.max_completions_per_session -
        this.adventureTeam.challengeCompletions[this.currentStage][this.$route.params.challengeIndex-1] == 0
      } else {
        return false
      }
    },
    skippable(){
      if (this.challenge && this.challenge.skippable && !this.challenge.skippable_after_x_tries && !this.challenge.skippable_after_x_mins){
        return true
      } else if (this.challengeTimerSkippable){
        return true
      } else if (this.challenge && this.challenge.skippable && this.challenge.skippable_after_x_tries){
        return this.challenge.answerLog.length >= this.challenge.skippable_after_x_tries
      }
      return false
    },
    challengeStagePoints(){
      let points = this.challenge ? this.challenge.points : 0
      if(
        this.challenge.no_stage_points_if_not_answered_correctly &&
        (
          !this.latestAnswerLogEntry ||
          (this.latestAnswerLogEntry && !this.latestAnswerLogEntry.isValidAnswer)
        )
      ) {
        points -= this.challenge.points
      }
      return points
    },
    multipleChoiceOptions() {
      var seed = this.adventureTeam.teamName.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
      function random() {
          var x = Math.sin(seed++) * 10000
          return x - Math.floor(x)
      }
      const toReturn = this.challenge.answers.map((answer, index) => {
        answer.answerIndex = index
        return answer
      })
      if (
        ['multiple-choice', 'free-multiple-choice'].includes(this.challenge.type) &&
        this.adventureTeam.uiMods.shuffleMultipleChoice)
      {
        toReturn.sort(() => 0.5 - random())
      }
      return toReturn
    },
    ...mapState(['userLocation', 'showNextStageAlert', 'adventureTeam', 'currentStage', 'latestAccessCode', 'currentSortedChallengesIndex'])
  },
  watch: {
    challenge(newValue, oldValue){
      // if(oldValue && newValue && newValue.hide_after_completion){
      //   if(newValue.completed){
      //     this.$router.push({ name: 'Stage' })
      //   }
      // }

      // if(
      //   newValue &&
      //   newValue.briefing.includes('iframe') &&
      //   (
      //     newValue.briefing.includes('accelerometer') ||
      //     newValue.briefing.includes('vr')
      //   )
      // ){
      //   // getOrientationPermission()
      // }
    },
    showAnswerBox(newValue, oldValue){
      if(!oldValue && newValue){
        if(
          this.challenge &&
          this.challenge.type.includes('image') &&
          this.latestAnswerLogEntry &&
          this.latestAnswerLogEntry.answerObj &&
          this.latestAnswerLogEntry.answerObj.value &&
          document.getElementById('preview-judged-image-answer')
        ){
          loadImage(
            this.latestAnswerLogEntry.answerObj.value,
            function (img) {
              document.getElementById('preview-judged-image-answer').appendChild(img)
            },
            { orientation: true }
          )
        }
      }
    },
    showChallengeTimer(newValue, oldValue){
      if(oldValue && !newValue){
        this.startChallengeTimerSequence()
      }
    },
    latestAnswerLogEntry(latestAnswerLogEntry, oldLatestAnswerLogEntry){
      if(
        latestAnswerLogEntry &&
        latestAnswerLogEntry.stageIndex === (this.stageIndex - 1) && // stage index starts from 1
        latestAnswerLogEntry.challengeIndex === this.challengeIndex
      ){
        const delay = Math.floor(Math.random() * 1500) + 300
        // this.showDrumRollScreen = true
        this.showResultScreen = true
        this.$store.commit('updateCurrentChallengeIndex', null)
        setTimeout(() => {
          // this.showDrumRollScreen = false
          if(!latestAnswerLogEntry.answerObj && latestAnswerLogEntry.isValidAnswer) {
            this.showResultScreen = false
          }
        }, delay)
      }
      if(latestAnswerLogEntry && latestAnswerLogEntry.timePenaltyTimestamp){
        this.disableAnswerSubmission = true
        this.checkTimePenalty()
        this.timePenaltyInterval = setInterval(() => {
          this.checkTimePenalty()
        }, 1000);
      }
      if(
        this.challenge &&
        this.challenge.type.includes('image') &&
        latestAnswerLogEntry &&
        latestAnswerLogEntry.answerObj &&
        latestAnswerLogEntry.answerObj.value &&
        document.getElementById('preview-judged-image-answer')
      ){
        loadImage(
          latestAnswerLogEntry.answerObj.value,
          function (img) {
            document.getElementById('preview-judged-image-answer').appendChild(img)
          },
          { orientation: true }
        )
      }
    },
    allowSubmitAnswer(newAllowSubmitAnswer, oldAllowSubmitAnswer){
      if(!oldAllowSubmitAnswer && newAllowSubmitAnswer){
        setTimeout(() => {
          window.scrollTo(0, 99999999999)
        }, 3000)
      }
    },
    answer: {
      handler(newValue, oldValue) {
        this.$store.commit('updateStopChallengeRouter', !!newValue)
      },
      deep: true
    },
    showResultScreen(newShowResultScreen, oldShowResultScreen) {
      if (
        !oldShowResultScreen && newShowResultScreen &&
        !(
          this.challenge.type !== 'no-answer' ||
          !this.adventureTeam.uiMods.skipCompletionScreenNoAnswer
        )
      ) {
        this.showResultScreen = false;
        if (this.adventureTeam.completedAt)
          this.$router.push({ name: 'Debrief' })
      }
    }
  },
  methods: {
    generateTimeRestrictedString() {
      let timeRestrictedString = '';
      if (this.challenge.time_restricted_allow_after_date || this.challenge.time_restricted_allow_after) {
        timeRestrictedString += `after ${this.challenge.time_restricted_allow_after_date || ''} ${this.challenge.time_restricted_allow_after || ''} `;
      }
      if (this.challenge.time_restricted_allow_before_date || this.challenge.time_restricted_allow_before) {
        if (timeRestrictedString.length > 0) {
          timeRestrictedString += `and before ${this.challenge.time_restricted_allow_before_date || ''} ${this.challenge.time_restricted_allow_before || ''} `;
        } else {
          timeRestrictedString += `before ${this.challenge.time_restricted_allow_before_date || ''} ${this.challenge.time_restricted_allow_before || ''} `;
        }
      }
      return timeRestrictedString.trim();
    },
    onScroll() {
      this.$store.commit('updateScrollPositions', {
        pageName: this.$route.name + this.stageIndex + this.challengeIndex,
        scrollPosition: window.top.scrollY
      })
    },
    startRefresherForRedoTimestamp () {
      if(
        this.challenge &&
        this.challenge.redoTimestamp &&
        (new Date()).getTime() < (new Date(this.challenge.redoTimestamp))
      ){
        setInterval(() => {
          if((new Date()).getTime() >= (new Date(this.challenge.redoTimestamp))) {
            location.reload()
          }
        }, 1000)
      }
    },
    openGoogleMapsDirections (lat, lon) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lon}`)
    },
    triggerProgressBar(loadingValue) {
      if(loadingValue < 80){
        this.loadingValue = loadingValue
        setTimeout(() => {
          const newValue = this.loadingValue + Math.random() * (5 - 2) + 2
          this.loadingValue = newValue
          this.triggerProgressBar(newValue)
        }, Math.random() * (1000 - 500) + 500)
      }
    },
    reload() {
      location.reload()
    },
    valueScanned(scannedText) {
      this.answer = scannedText
      this.submitAnswer()
    },
    showPhotoViewer(event){
      this.$store.commit('showPhotoViewer', event.target.dataset.url)
    },
    distanceBetweenTwoGeoCoordinatesInMeters(lat1, lon1, lat2, lon2) {
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0
      }
      else {
        const radlat1 = Math.PI * lat1/180
        const radlat2 = Math.PI * lat2/180
        const theta = lon1-lon2
        const radtheta = Math.PI * theta/180
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
          dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        dist = dist * 1.609344
        return dist*1000
      }
    },
    getTimeLimitText(endTime){
      const startTime = new Date();
      let msec = endTime - startTime;
      if(msec > 0){
        const hours = Math.floor(msec / 1000 / 60 / 60)
        msec -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(msec / 1000 / 60)
        msec -= minutes * 1000 * 60;
        const seconds = Math.floor(msec / 1000)
        msec -= seconds * 1000
        const hourString = hours > 0 ? `${hours < 10 ? `0${hours}` : `${hours}`}:` : ''
        const minuteString = `${minutes < 10 ? `0${minutes}` : `${minutes}`}:`
        const secondString = `${seconds < 10 ? `0${seconds}` : `${seconds}`}`
        this.timeLimitText = `${hourString}${minuteString}${secondString}`
      } else {
        if(
          this.stageDetails.auto_forward_when_time_is_up
        ){
          this.$store.dispatch('triggerAutoForward', {
            stage: this.stageIndex
          })
        }
        clearInterval(this.interval)
        this.timeLimitText = "TIME'S UP"
      }
    },
    getChallengeTimerText(startTime){
      const nowTime = new Date()
      let msec = nowTime - startTime
      if(
          this.challenge.hint &&
          !this.challenge.hint.hint &&
          this.challenge.hint.reveal_after_x_mins &&
          msec/60000 > this.challenge.hint.reveal_after_x_mins
      ){
        this.buyHint(true)
      }
      if(
          this.challenge.skippable_after_x_mins &&
          msec/60000 > this.challenge.skippable_after_x_mins
      ){
        this.challengeTimerSkippable = true
      }
      if(msec > 0){
        const hours = Math.floor(msec / 1000 / 60 / 60)
        msec -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(msec / 1000 / 60)
        msec -= minutes * 1000 * 60;
        const seconds = Math.floor(msec / 1000)
        msec -= seconds * 1000
        const hourString = hours > 0 ? `${hours < 10 ? `0${hours}` : `${hours}`}:` : ''
        const minuteString = `${minutes < 10 ? `0${minutes}` : `${minutes}`}:`
        const secondString = `${seconds < 10 ? `0${seconds}` : `${seconds}`}`
        this.challengeTimerText = `${hourString}${minuteString}${secondString}`
      }
    },
    selectChoice(selectedChoiceIndex) {
      if(this.choiceIndexes.includes(selectedChoiceIndex)){
        this.choiceIndexes = this.choiceIndexes.filter(choiceIndex => {
          return selectedChoiceIndex !== choiceIndex
        })
      } else {
        this.choiceIndexes = [selectedChoiceIndex, ...this.choiceIndexes]
      }

      if (
        this.challenge.max_no_of_selectable_options &&
        this.choiceIndexes.length > this.challenge.max_no_of_selectable_options
      ) {
        this.choiceIndexes = this.choiceIndexes.slice(0, -1)
      }
    },
    checkTimePenalty() {
      if(!this.latestAnswerLogEntry ||
        this.latestAnswerLogEntry && !this.latestAnswerLogEntry.timePenaltyTimestamp
      ){
        clearInterval(this.timePenaltyInterval)
        this.disableAnswerSubmission = false
        return
      }
      const endTime = Date.parse(this.latestAnswerLogEntry.timePenaltyTimestamp)
      const startTime = new Date()
      let msec = endTime - startTime
      if(msec >= 0){
        this.disableAnswerSubmission = true
        const hours = Math.floor(msec / 1000 / 60 / 60)
        msec -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(msec / 1000 / 60)
        msec -= minutes * 1000 * 60;
        const seconds = Math.floor(msec / 1000)
        msec -= seconds * 1000
        this.timePenaltyText = `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s ` : ''}`
      } else {
        clearInterval(this.timePenaltyInterval)
        this.disableAnswerSubmission = false
      }
    },
    startChallengeTimerSequence() {
      const challengeTimerStartTime = this.challenge.startedAt.toDate()
      this.getChallengeTimerText(challengeTimerStartTime)
      this.challengeTimerInterval = setInterval(() => {
        this.getChallengeTimerText(challengeTimerStartTime)
      }, 1000)
    },
    startChallengeTimer() {
      this.loading = true
      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
          methodName: 'start-challenge-timer',
          teamCode: this.$store.state.teamCode,
          userName: this.$store.state.userName,
          stage: this.stageIndex,
          challengeIndex: this.challengeIndex
      }).then(() => {
        this.loading = false
      }).catch(err => {
        alert(`Error, please reload.`)
        this.loading = false
      })
    },
    checkIfWithinTimeRestrictions() {
      if(
        !this.$store.state.userName.includes('TESTING') &&
        this.challenge.time_restricted
      ){
        let afterTest = true
        let beforeTest = true
        const afterTime = this.challenge.time_restricted_allow_after // HH:mm, can be null
        const afterDate = this.challenge.time_restricted_allow_after_date // YYYY-MM-DD, can be null
        const beforeTime = this.challenge.time_restricted_allow_before // HH:mm, can be null
        const beforeDate = this.challenge.time_restricted_allow_before_date // YYYY-MM-DD, can be null

        let afterDateTime = null
        let beforeDateTime = null
        if (afterTime && afterDate) afterDateTime = new Date(`${afterDate}T${afterTime}`)
        if (beforeTime && beforeDate) beforeDateTime = new Date(`${beforeDate}T${beforeTime}`)

        const now = new Date()
        if (afterDateTime) {
          afterTest = now >= afterDateTime
        } else if (afterTime) {
          const nowHours = now.getHours() < 10 ? `0${now.getHours()}` : `${now.getHours()}`
          const nowMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`
          const nowTime = `${nowHours}:${nowMinutes}`
          afterTest = nowTime >= afterTime
        } else if (afterDate) {
          const nowDate = now.toISOString().split('T')[0] // YYYY-MM-DD
          afterTest = nowDate >= afterDate
        }
        if (beforeDateTime) {
          beforeTest = now <= beforeDateTime
        } else if (beforeTime) {
          const nowHours = now.getHours() < 10 ? `0${now.getHours()}` : `${now.getHours()}`
          const nowMinutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : `${now.getMinutes()}`
          const nowTime = `${nowHours}:${nowMinutes}`
          beforeTest = nowTime <= beforeTime
        } else if (beforeDate) {
          const nowDate = now.toISOString().split('T')[0] // YYYY-MM-DD
          beforeTest = nowDate <= beforeDate
        }
        return afterTest && beforeTest
      }
      return true
    },
    checkIfLocationIsSuitable() {
      if (this.$store.state.userName.includes('TESTING')) return true

      if(
        (
          this.challenge &&
          this.challenge.position && (
            !this.userLocation ||
            this.challenge.position.radius_in_meters < this.distanceBetweenTwoGeoCoordinatesInMeters(
              this.userLocation.lat, this.userLocation.lon, this.challenge.position.lat, this.challenge.position.lon
            )
          ) &&
          !this.challenge.position.allowCompletionOutsideRadius
        ) ||
        (
          this.challenge &&
          this.challenge.type === 'direction' && (
            !this.userLocation ||
            this.challenge.answers[0].radius_in_meters < this.distanceBetweenTwoGeoCoordinatesInMeters(
              this.userLocation.lat, this.userLocation.lon, this.challenge.answers[0].lat, this.challenge.answers[0].lon
            )
          )
        )
      ){
        this.showOutsideLocationScreen = true
        return false
      }
      return true
    },
    async submitAnswer(completedWithTeamNames = null) {
      // HACK: Remove after NYP open house 15 Jan 2025 - Mohamed Afiq
      if (this.challenge.name === "Engrave your Luggage Tag (b)") {
        const alphanumericRegex = /^[a-z0-9]+$/i;
        if (!alphanumericRegex.test(this.answer) || this.answer.length > 8) {
          alert('Your answer should only contain letters and numbers, and must be 8 characters or less.');
          this.loading = false;
          return;
        }
      }

      this.$store.commit('updateStopChallengeRouter', false)
      if(!this.checkIfWithinTimeRestrictions()){
        alert('Currently time-restricted.')
        return
      }
      if(!this.checkIfLocationIsSuitable()){ return }
      this.loading = true
      let answerObj = {}

      if (this.challenge.must_complete_with_x_other_teams && !completedWithTeamNames) {
        this.showSelectTeamNameModal = true
        this.loading = false
        return
      } else {
        this.showSelectTeamNameModal = false
      }

      if (this.getTaskTypeConfig(this.challenge.type, 'showProgressBar')) {
        this.triggerProgressBar(0)
      }

      if (this.getTaskTypeConfig(this.challenge.type, 'dataType') === 'text'){
        answerObj.value = this.answer.trim()
      } else if(this.getTaskTypeConfig(this.challenge.type, 'dataType') === 'number'){
        answerObj.value = Number(this.answer)
      } else if (this.challenge.type === 'location'){
        if (!this.answer) {
          this.loading = false
          alert('Please update your location first...')
          return
        }
        answerObj = this.answer
      } else if (this.getTaskTypeConfig(this.challenge.type, 'dataType') === 'image'){
        if (!this.answer) {
          this.loading = false
          return
        }
        this.answer.image = await compressImage(this.answer.image)
        this.answer.ext = '.webp'

        const filename = Date.now().toString() + this.answer.ext
        await firebaseApp.storage()
        .ref(`/judgementFiles/${this.$store.state.teamCode}/${filename}`)
        .put(this.answer.image)
        await firebaseApp.storage()
        .ref(`/judgementFiles/${this.$store.state.teamCode}/${filename}`)
        .getDownloadURL().then(async downloadURL => {
          const response = await fetch(downloadURL)
          const blob = await response.blob()
          if (blob.size < 10) {
            alert('Error found during upload, please re-attempt')
            location.reload()
            return
          }
          answerObj.value = downloadURL
        })
        answerObj.ext = this.answer.ext
      } else if (this.getTaskTypeConfig(this.challenge.type, 'dataType') === 'video'){
        if (!this.answer) {
          this.loading = false
          return
        }
        this.triggerProgressBar(0)
        const filename = Date.now().toString() + this.answer.ext
        const filePath = `/judgementFiles/${this.$store.state.teamCode}/${filename}`
        try {
          await firebaseApp.storage()
          .ref(filePath)
          .put(this.answer.video)
          const downloadURL = await firebaseApp.storage()
          .ref(filePath)
          .getDownloadURL()
          answerObj.value = downloadURL
        } catch (error) {
          console.error("Error uploading video or fetching URL:", error)
          this.loading = false
          alert("Failed to upload video. Please try again.")
        }
        // const compressVideo = firebaseApp.functions('asia-northeast1').httpsCallable('compressVideo')
        // await compressVideo({
        //     filePath: `judgementFiles/${this.$store.state.teamCode}/${filename}`
        // }).then(result => {
        //   if (result.data.fileUrl) {
        //     answerObj.value = result.data.fileUrl
        //   }
        // }).catch(err => {
        //   alert(`Compression error`)
        //   this.loading = false
        // })
        answerObj.ext = this.answer.ext
      } else if (this.getTaskTypeConfig(this.challenge.type, 'dataType') === 'file'){
        if (!this.answer) {
          this.loading = false
          return
        }
        this.triggerProgressBar(0)
        const filename = this.answer.filename + ' - ' + new Date().toISOString()  + this.answer.ext
        const filePath = `/judgementFiles/${this.$store.state.teamCode}/${filename}`
        try {
          await firebaseApp.storage()
          .ref(filePath)
          .put(this.answer.document)
          const downloadURL = await firebaseApp.storage()
          .ref(filePath)
          .getDownloadURL()
          answerObj.value = downloadURL
        } catch (error) {
          console.error("Error uploading file or fetching URL:", error)
          this.loading = false
          alert("Failed to upload file. Please try again.")
        }
        answerObj.ext = this.answer.ext
        answerObj.filename = this.answer.filename
      } else if (this.getTaskTypeConfig(this.challenge.type, 'dataType') === 'audio'){
        this.triggerProgressBar(0)
        const filename = Date.now().toString() + '.wav'
        // const storageRef = firebaseApp.storage().ref(`/judgementFiles/${this.$store.state.teamCode}/${filename}`)
        // Upload Blob file to Firebase
        const snapshot = await firebaseApp.storage()
        .ref(`/judgementFiles/${this.$store.state.teamCode}/${filename}`)
        .put(this.answer.audio)
        await firebaseApp.storage()
        .ref(`/judgementFiles/${this.$store.state.teamCode}/${filename}`)
        .getDownloadURL().then(downloadURL => {
          answerObj.value = downloadURL
        })
        answerObj.ext = '.wav'
      } else if (['multiple-choice', 'free-multiple-choice'].includes(this.challenge.type)){
        answerObj.choiceIndexes = this.choiceIndexes
      } else if (this.challenge.type === 'direction'){
        if (!this.userLocation) {
          this.loading = false
          alert('Please wait for GPS signal...')
          return
        }
        answerObj.lat = this.userLocation.lat
        answerObj.lon = this.userLocation.lon
      } else if(this.challenge.type === 'matching-pairs'){
        answerObj.value = this.answer.trim()
      } else if(this.challenge.type === 'image-multiple'){
        answerObj.value = this.answer
        if (!this.answer || this.answer.length === 0) {
          this.loading = false
          return
        }
        if (
          !confirm(`You will be submitting ${this.answer.length} image${this.answer.length > 1 ? 's' : ''}. Click OK to confirm or Cancel to add more images.`)
        ) {
          this.loading = false
          return
        }
      }

      const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
      masterFunction({
          methodName: 'answer-challenge',
          teamCode: this.$store.state.teamCode,
          userName: this.$store.state.userName,
          stage: this.stageIndex,
          challengeIndex: this.challengeIndex,
          position: this.adventureTeam.requireTeamPosition ? this.$store.state.userLocation: null,
          answerObj,
          completedWithTeamNames
      }).then(() => {
        let delay = Math.floor(Math.random() * 1500) + 500
        if(['no-answer', 'judged-image', 'judged-text', 'judged-video', 'judged-file'].includes(this.challenge.type)){
          delay = 0
        } else if (['multiple-choice', 'free-multiple-choice'].includes(this.challenge.type)){
          this.choiceIndexes = []
        }
        this.showDrumRollScreen = true
        this.showResultScreen = true
        setTimeout(() => {
          this.showDrumRollScreen = false
        }, delay)
        this.loading = false
        this.startRefresherForRedoTimestamp()
      }).catch(err => {
        alert(err)
        location.reload()
        this.loading = false
      })
    },
    buyHint(isReveal) {
      // if (this.challenge.hint && this.challenge.hint.penalty) {
      //   if (
      //     !confirm(
      //       `You will lose ${this.challenge.hint.penalty} ${this.$store.state.uiMods.point.toLowerCase()}${this.challenge.hint.penalty === 1 ? '' : 's'} for revealing this ${this.$store.state.uiMods.clue.toLowerCase()}. Click OK to confirm.`
      //       )
      //     ) return
      // }
      if (this.challenge.hint.hint && isReveal) {
        this.toggleHintScreen()
        return
      }

      if(!this.loading){
        // to be deleted after May 15 2024 eaproductions hack
        if(this.adventureTeam.adventureId === "UMMU" && !confirm("Are you lost? Click OK to confirm. 5 points will be deducted from your total score.")) {
          return
        }
        this.loading = true
        const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
          masterFunction({
              methodName: 'buy-hint',
              teamCode: this.$store.state.teamCode,
              userName: this.$store.state.userName,
              stage: this.stageIndex,
              challengeIndex: this.challengeIndex,
              isReveal
          }).then(() => {
              this.loading = false
              if (isReveal) this.toggleHintScreen()
          }).catch(() => {
              this.loading = false
          })
      }
    },
    skipTask() {
      if(!this.loading){
        this.loading = true
        const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
          masterFunction({
              methodName: 'give-up',
              teamCode: this.$store.state.teamCode,
              userName: this.$store.state.userName,
              stage: this.stageIndex,
              challengeIndex: this.challengeIndex
          }).then(() => {
              this.loading = false
              this.toggleSkipScreen()
              this.showResultScreen = true
          }).catch(() => {
              this.loading = false
              this.toggleSkipScreen()
          })
      }
    },
    toggleResultScreen() {
      this.showResultScreen = !this.showResultScreen
    },
    toggleHintScreen() {
      if(!this.showHintScreen){
        this.loading = true
        setTimeout(() => { this.loading = false }, 1500)
      }
      this.showHintScreen = !this.showHintScreen
    },
    toggleSkipScreen() {
      if(!this.showSkipScreen){
        this.loading = true
        setTimeout(() => { this.loading = false }, 1500)
      }
      this.showSkipScreen = !this.showSkipScreen
    },
    processNextTask() {
      this.showResultScreen = false
      this.$store.commit('updateStopChallengeRouter', false)

      if (
        this.adventureTeam.completedAt
      ){
        this.$router.push({ name: 'Debrief' })
      } else if (
        this.stageDetails.complete_challenges_in_order &&
        this.challengeIndex !== this.stageDetails.challenges.length - 1
      ) {
        this.$router.push({
          name: 'Challenge',
          params: {
            stageIndex: this.currentStage,
            challengeIndex: this.stageDetails.challenges_sequence ?
            this.stageDetails.challenges_sequence[this.stageDetails.challenges_sequence.indexOf(this.challengeIndex) + 1] + 1 :
            this.challengeIndex + 2
          }
        })
      } else if (
        (this.challenge.open_next_challenge || this.challenge.hide_next_challenge)
        &&
        this.challengeIndex !== this.stageDetails.challenges.length - 1
      ) {
        this.$router.push({
          name: 'Challenge',
          params: {
            stageIndex: this.currentStage,
            challengeIndex: this.challengeIndex + 2
          }
        })
      } else if(this.stageIndexProp) {
        this.$store.commit('updateShowNextStageAlert', false)
        this.$store.commit('updateCurrentStage', this.adventureTeam.stage)
        this.toggleResultScreen()
        setTimeout(() => { window.scrollTo(0,0) }, 1500)
      }
      // else if (!this.showNextStageAlert) {
      //   this.$router.push({ name: 'Stage' })
      // }
    },
    processPowerupImmediateUse() {
      if (
        !this.adventureTeam.uiMods.powerupsUseImmediately ||
        !this.adventureTeam.powerups
      ) return

      for (const powerup of this.adventureTeam.powerups) {
        if (
          !powerup.isUsed && !powerup.justSayNo &&
          (powerup.stars || powerup.time)
        ) {
          alert(
            `Your team has an unused ${this.$store.state.uiMods.powerup.toLowerCase()}. Click on the “${this.$store.state.uiMods.powerup}s” tab to use it before attempting the next station`)
          this.$router.push({ name: 'Powerups' })
        }
      }
    },
    openTab(url) {
      window.open(url, '_blank')
    },
    formatTime(seconds) {
      if (seconds < 60) {
        return seconds === 0 ? '0' : `${seconds} sec${seconds === 1 ? '' : 's'}`;
      } else {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const minutePart = `${minutes} min${minutes === 1 ? '' : 's'}`;
        const secondPart = remainingSeconds === 0 ? '' : ` ${remainingSeconds} sec${remainingSeconds === 1 ? '' : 's'}`;
        return `${minutePart}${secondPart}`;
      }
    },
  },
  created() {
    if(this.stageDetails && this.stageDetails.time_limit_timestamp){
      const endTime = this.stageDetails.time_limit_timestamp.toDate()
      this.getTimeLimitText(endTime)
      this.interval = setInterval(() => {
        this.getTimeLimitText(endTime)
      }, 1000)
    }

    if(this.challenge && this.challenge.startedAt){
      this.startChallengeTimerSequence()
    }
  },
  beforeDestroy() {
    if(this.interval){
      clearInterval(this.interval)
    }
    if(this.challengeTimerInterval){
      clearInterval(this.challengeTimerInterval)
    }
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style scoped>
.hint-modal {
  flex-direction: column-reverse !important;
  justify-content: end !important;
  z-index: 0;
  padding-bottom: 55px;
}

.task-modal-modifier {
  z-index: 1000;
}

.card-shadow-modifier {
  box-shadow: none;
}
</style>
