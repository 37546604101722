<template>
  <div>
    <button
      v-if="isButton"
      @click="changeTeamName"
      class="button is-primary-colors is-small is-outlined is-rounded"
      :class="{ 'has-text-stroke': isTextStroke }"
    >
      <i class="fas fa-edit mr-1"></i>
      Change team name
    </button>
    <i
      v-else
      :class="[
        'fas fa-edit pointer has-text-white',
        isTextStroke ? 'has-text-stroke' : ''
      ]"
      @click="changeTeamName"
    ></i>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'ChangeTeamNameButton',
  props: {
    isTextStroke: {
      type: Boolean,
      default: false
    },
    teamCode: {
      type: String,
      required: true
    },
    isButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async changeTeamName() {
      const newTeamName = prompt('Enter new team name, or cancel');
      if (newTeamName && newTeamName.length > 0) {
        try {
          const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction');
          await masterFunction({
            methodName: 'change-team-name',
            teamCode: this.teamCode,
            newTeamName: newTeamName
          });
          alert(`Team name changed to "${newTeamName}" successfully.`);
        } catch (error) {
          alert('That team name is already taken, please use another name.');
        }
      }
    }
  }
}
</script>
