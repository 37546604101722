import posthog from 'posthog-js'

export default {
  install (Vue: any) {
    posthog.init(
      'phc_8YonerxRLyMl1PPcyp5iGcg069lokO4Rw2fFd8XiDXG',
      {
        api_host: 'https://eu.i.posthog.com',
        capture_pageview: false
      }
    )

    Vue.prototype.$posthog = posthog
  }
}
