<template>
  <div class="mb-3">
    <div
      v-for="(x, index) in leftOrder"
      :key="index"
      class="level is-mobile mb-0"
    >
      <div
        @click="showAlert"
        class="matching-pair-box-modifier is-selectable has-text-centered"
      >
        <img
          v-if="pairs[x][0].includes('http')"
          :src="pairs[x][0]"
          class="matching-pair-image no-zoom"
        />
        <div v-else class="notranslate has-text-centered">
        {{ pairs[x][0] }}
        </div>
      </div>
      <div>
        <i class="fas fa-arrows-alt-h"></i>
      </div>
      <div
        @click="handleSelectedIndex(index)"
        class="matching-pair-box-modifier is-selectable has-text-centered"
        :class="{
          'selected-box': index === selectedIndex
        }"
      >
        <img
          v-if="pairs[rightOrder[index]][1].includes('http')"
          :src="pairs[rightOrder[index]][1]"
          class="matching-pair-image no-zoom"
        />
        <div
          v-else
          class="notranslate has-text-centered"
        >
        {{ pairs[rightOrder[index]][1] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatchingPairsInput',
  props: {
    pairs: {
      type: Array,
      default: () => []
    },
    answer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      leftOrder: [],
      rightOrder: [],
      selectedIndex: null,
      selectedIndex2: null
    }
  },
  methods: {
    showAlert () {
      if (this.answer) return
      alert('Move the items on the right side instead. Click on one box, and then click on another box to switch their places.')
    },
    handleSelectedIndex (index) {
      if (this.answer) return

      if (this.selectedIndex === null) {
        this.selectedIndex = index
      } else if (this.selectedIndex === index) {
        this.selectedIndex = null
      } else {
        this.selectedIndex2 = index
      }

      if (this.selectedIndex !== null && this.selectedIndex2 !== null) {
        const temp = this.rightOrder[this.selectedIndex]
        this.rightOrder[this.selectedIndex] = this.rightOrder[this.selectedIndex2]
        this.rightOrder[this.selectedIndex2] = temp
        this.selectedIndex = null
        this.selectedIndex2 = null

        this.updateAnswer()
      }
    },
    updateAnswer () {
      let answers = []
      this.rightOrder.forEach((order, index) => {
        // answer += this.pairs[order][1]
        answers.push(
          [this.pairs[this.leftOrder[index]][0], this.pairs[order][1]]
        )
      })
      answers = JSON.stringify(answers)
      this.$emit('update', answers)
    }
  },
  computed: {
    noOfRows () {
      return this.pairs.length
    }
  },
  mounted () {
    this.pairs.forEach((pair, index) => {
      this.leftOrder.push(index)
      this.rightOrder.push(index)
    })

    if (this.answer) return

    let shuffled = false
    let attempts = 0
    while (!shuffled && attempts < 30) {
      this.rightOrder.sort(() => 0.5 - Math.random())
      let displacedItems = 0
      this.rightOrder.forEach((item, index) => { if (item !== index) displacedItems++ })
      if (displacedItems / this.rightOrder.length >= 0.5) shuffled = true
      attempts++
    }

    this.updateAnswer()
  }
}
</script>

<style>
.matching-pair-box-modifier {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  /* height: 120px; */
  margin: 3px 5px !important;
  padding: 15px !important;
  border: 1px solid darkgrey;
  border-radius: 5px;
}

.matching-pair-image {
  border-radius: 5px;
  max-height: 100%;
  object-fit: contain;
}

.selected-box {
  background-color: rgb(142, 142, 142);
}
</style>
