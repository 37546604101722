<template>
  <div class="height-fullscreen columns is-gapless is-centered is-mobile">
    <OverlapLoadingScreen v-if="!adventureTeam || !adventureTeam.stageDetails"/>
    <div v-if="adventureTeam && adventureTeam.stageDetails" class="column is-12-mobile is-10-tablet is-8-desktop is-6-widescreen is-6-fullhd mb-4">
      <div v-if="!adventureTeam.completedAt" class="a pb-2 pt-3">
        <div class="level is-mobile is-vcentered px-0 mx-0 mt-0 mb-2 py-0">
          <div class="level-left">
            <div class="level-item mr-1">
              <i
              @click="$router.push({ name: 'Exit' })"
              class="fas fa-1x fa-sign-out-alt pointer pl-4 has-text-white is-size-4"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour }"
              ></i>
            </div>
            <div class="level-item">
              <i
              v-if="adventureTeam.stageDetails.chapter_music_url"
              @click="showBackgroundMusicScreen = true"
              class="fas fa-1x fa-music pointer pl-3 has-text-white is-size-4 rotating-left-right"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour }"
              ></i>
            </div>
          </div>
          <div class="level-right">
            <!-- <div class="level-item">
              <i
              v-if="adventureTeam.allowFaciMessaging === true"
              @click="goTo('FaciMessaging')"
              class="fas fa-headset pointer pr-1 has-text-white is-size-5 is-pulled-right"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url }"
              ></i>
            </div> -->
            <ChangeTeamNameButton
            class="level-item mr-4"
            v-if="adventureTeam.uiMods.allowChangeTeamName"
            :teamCode="adventureTeam.teamCode" :isTextStroke="adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour"/>
            <div v-if="adventureTeam.allowTranslation && !adventureTeam.defaultLanguage" class="level-item mr-1">
              <i
              @click="goToTranslator()"
              class="fas fa-language pointer pr-4 has-text-white is-size-4"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour }"
              ></i>
            </div>
            <div v-if="showGallery" class="level-item">
              <i
              @click="goTo('Gallery')"
              class="fas fa-photo-video pointer pr-4 has-text-white is-size-5"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour }"
              ></i>
            </div>
            <div v-if="showLeaderboard" class="level-item">
              <i
              @click="goTo('Leaderboard')"
              class="fas fa-trophy pointer pr-4 has-text-white is-size-5"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour }"
              ></i>
            </div>
          </div>
        </div>
        <div class="container is-mobile mr-3 is-vcentered pl-3">
          <div
          v-if="!$store.state.uiMods.hidePointsProgressBar"
          class="pt-0"
          >
            <div class="level is-mobile mb-1">
              <div class="level-left">
                <div class="level-item">
                  <div
                    :key="`points${adventureTeam.points}`"
                    class=" has-text-weight-semibold"
                    :class="{
                      'is-size-4 has-text-light': !adventureTeam.stageDetails.chapter_background_url && !adventureTeam.uiMods.defaultBackgroundColour,
                      'is-size-6 tag is-light is-rounded': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour
                    }"
                    >
                    {{ !adventureTeam.uiMods.useGameWideProgressBar ? adventureTeam.stagePoints : adventureTeam.points }} {{ $store.state.uiMods.point.toLowerCase() }}{{adventureTeam.stagePoints === 1 ? '' : 's'}}
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div v-if="!$store.state.uiMods.hidePointsSubtext" class="level-item">
                  <div
                    :key="`pointsLeft${adventureTeam.stagePoints}`"
                    class="is-size-7"
                    :class="{
                      'has-text-light': !adventureTeam.stageDetails.chapter_background_url && !adventureTeam.uiMods.defaultBackgroundColour,
                      'tag is-light is-rounded': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour
                    }"
                    >
                      {{ !adventureTeam.uiMods.useGameWideProgressBar ? adventureTeam.stageDetails.min_points - adventureTeam.stagePoints : adventureTeam.totalStagePoints - adventureTeam.points }} {{t('more needed')}}
                    </div>
                  </div>
                </div>
            </div>
            <progress
            v-if="!adventureTeam.uiMods.useGameWideProgressBar"
            class="progress mb-2"
            :class="adventureTeam.uiMods.progressBarColour ? adventureTeam.uiMods.progressBarColour : 'is-primary'"
            v-bind:value="adventureTeam.stagePoints/adventureTeam.stageDetails.min_points*100"
            v-bind:max="100"/>
            <progress
            v-else-if="adventureTeam.uiMods.useGameWideProgressBar"
            class="progress mb-2"
            :class="adventureTeam.uiMods.progressBarColour ? adventureTeam.uiMods.progressBarColour : 'is-primary'"
            v-bind:value="adventureTeam.points/adventureTeam.totalStagePoints*100"
            v-bind:max="100"/>
          </div>
          <div
            v-if="timeLimitText"
            :key="timeLimitText"
            class="is-size-7"
            :class="{
              'has-text-light mt-2': !adventureTeam.stageDetails.chapter_background_url && !adventureTeam.uiMods.defaultBackgroundColour,
              'tag is-light is-rounded': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour
            }"
          >
            <i class="fas fa-fw fa-stopwatch"></i> <b>{{ timeLimitText }}</b>
          </div>
        </div>
      </div>
      <div v-if="adventureTeam.completedAt" class="a pb-2 pt-3">
        <div class="level is-mobile is-vcentered px-0 mx-0 mt-0 mb-2 py-0">
          <div class="level-left">
            <div class="level-item">
              <i
              @click="$router.push({ name: 'Exit' })"
              class="fas fa-1x fa-sign-out-alt pointer pl-4 has-text-white is-size-4"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour }"
              ></i>
            </div>
            <div class="level-item">
              <i
              v-if="adventureTeam.stageDetails.chapter_music_url"
              @click="showBackgroundMusicScreen = true"
              class="fas fa-1x fa-music pointer pl-3 has-text-white is-size-4 rotating-left-right"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour }"
              ></i>
            </div>
          </div>
          <div class="level-right">
            <!-- <div v-if="adventureTeam.allowTranslation" class="level-item mr-1">
            <i
              @click="goToTranslator()"
              class="fas fa-language pointer pr-4 has-text-white is-size-4"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url }"
              ></i>
            </div> -->
            <div v-if="showGallery" class="level-item">
              <i
              @click="goTo('Gallery')"
              class="fas fa-photo-video pointer pr-4 has-text-white is-size-5"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour }"
              ></i>
            </div>
            <div v-if="showLeaderboard" class="level-item">
              <i
              @click="goTo('Leaderboard')"
              class="fas fa-trophy pointer pr-4 has-text-white is-size-5 is-pulled-right"
              v-bind:class="{ 'has-text-stroke': adventureTeam.stageDetails.chapter_background_url || adventureTeam.uiMods.defaultBackgroundColour }"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div>
        <transition-page>
          <router-view :key="$route.fullPath"/>
        </transition-page>
      </div>
    <transition name="fade">
      <div
      class="modal"
      v-if="showTimesUpScreen"
      v-bind:class="{'is-active': showTimesUpScreen}"
      >
        <div class="modal-background pointer"></div>
        <div class="modal-content result-modal">
          <div class="card mx-4">
            <div class="card-content">
              <div class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                Time limit exceeded
              </div>
              <div v-if="!adventureTeam.endAfterTimeLimit" class="container has-text-centered mb-3">
                The {{ $store.state.uiMods.game.toLowerCase() }} has not yet ended. You still need to complete {{ $store.state.uiMods.task.toLowerCase() }}s to end the {{ $store.state.uiMods.game.toLowerCase() }}.
                <div v-if="!adventureTeam.hideLeaderboard" class="container has-text-centered mt-1">
                  However, no more points will be awarded onto the leaderboard.
                </div>
              </div>
              <div v-else class="container has-text-centered mb-3">
                The {{ $store.state.uiMods.game.toLowerCase() }} has ended.
              </div>
              <div @click="toggleTimesUpScreen()" class="button is-primary-colors has-text-weight-semibold is-fullwidth">
                {{ t('Close') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    </div>
    <ChatPopUp />
    <JudgementResultPopUp :task="newJudgementResultPopUpTask"/>
    <BackgroundMusic :show="showBackgroundMusicScreen" v-on:hide="showBackgroundMusicScreen = false"/>
    <GameNavbar/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getFirestore, doc, getDoc, onSnapshot } from 'firebase/firestore'
import firebaseApp from '@/firebase/init'
import OverlapLoadingScreen from '@/components/OverlapLoadingScreen.vue'
import TransitionPage from '@/components/TransitionPage.vue'
import GameNavbar from '@/components/GameNavbar.vue'
import BackgroundMusic from '../components/BackgroundMusic.vue'
import ChatPopUp from '../components/ChatPopUp.vue'
import JudgementResultPopUp from '../components/JudgementResultPopUp.vue'
import ChangeTeamNameButton from '../components/ChangeTeamNameButton.vue'

export default {
  name: 'Game',
  components: {
    TransitionPage,
    GameNavbar,
    OverlapLoadingScreen,
    BackgroundMusic,
    ChatPopUp,
    JudgementResultPopUp,
    ChangeTeamNameButton
  },
  data() {
    return {
      adventureTeam: null,
      adventureView: null,
      timeLimitText: null,
      timeLimitInterval: null,
      pageTitle: null,
      showTimesUpScreen: false,
      showBackgroundMusicScreen: false,
      photoViewerKey: 0,
      viewer: null,
      newJudgementResultPopUpTask: null,
      pageInteractTimestamp: null
    }
  },
  computed: {
    showLeaderboard () {
      if (
        !this.adventureTeam.hideLeaderboard &&
        !this.adventureTeam.showLeaderboardAtCompletion
      ) {
        return true
      } else if (
        !this.adventureTeam.hideLeaderboard &&
        this.adventureTeam.showLeaderboardAtCompletion &&
        this.adventureTeam.completedAt
      ) {
        return true
      }
      return false
    },
    showGallery() {
      return (
        this.adventureTeam &&
        this.adventureTeam.answerLog &&
        this.adventureTeam.answerLog.filter(x => x.answerObj.ext).length > 0
      )
    },
    ...mapState(['teamCode', 'photoViewerUrl', 'showPhotoViewer', 'currentStage'])
  },
  methods: {
    goToTranslator(){
      this.$store.commit('updateTranslatorReturnPath', this.$route.path)
      this.$router.push({ name: 'Translation' })
    },
    showViewerJs() {
      this.viewer = this.$viewerApi({
        images: [this.photoViewerUrl],
        options: {
          navbar: false,
          title: false,
          button: true
        },
      })
      this.$store.commit('hidePhotoViewer')
    },
    zeroFill(number,width){
      width -= number.toString().length;
      if ( width > 0 )
      {
          return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
      }
      return number + ""; // always return a string
    },
    getTimeLimitText(endTime){
      const startTime = new Date();
      let msec = endTime - startTime;
      if(msec > 0){
        const hours = Math.floor(msec / 1000 / 60 / 60)
        msec -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(msec / 1000 / 60)
        msec -= minutes * 1000 * 60;
        const seconds = Math.floor(msec / 1000)
        msec -= seconds * 1000;
        const hourString = hours > 0 ? `${hours < 10 ? `0${hours}` : `${hours}`}:` : ''
        const minuteString = `${minutes < 10 ? `0${minutes}` : `${minutes}`}:`
        const secondString = `${seconds < 10 ? `0${seconds}` : `${seconds}`}`
        this.timeLimitText = `${hourString}${minuteString}${secondString}`
      } else {
        if(this.adventureTeam && this.adventureTeam.endAfterTimeLimit){
          clearInterval(this.timeLimitInterval)
          this.$store.dispatch('triggerAutoForward', {
            stage: this.stageIndex,
            endGame: true
          })
        }
        this.timeLimitText = "TIME'S UP"
      }
    },
    goTo(routeName){
      if(this.currentRouteName !== routeName){
        this.$router.push({ name: routeName })
      }
    },
    toggleTimesUpScreen() {
      this.showTimesUpScreen = false
    },
    setFontFamily(fontUrl) {
      const newFont = new FontFace('userCustom', `url(${fontUrl})`)
      newFont.load().then(function(loadedFont) {
        document.fonts.add(loadedFont)
        setInterval(function(){
          document.getElementsByTagName('*').forEach(div => {
            if (
              div.style.fontFamily !== 'userCustom' &&
              div.tagName !== 'I'
            ) {
              div.style.fontFamily = "userCustom"
            }
          })
        }, 1000)
      })
    },
    async fetchAdventureTeam(teamCode) {
      const db = getFirestore(firebaseApp)
      const adventureTeamRef = doc(db, 'adventureTeams', teamCode)
      const adventureViewRef = doc(db, 'adventureViews', teamCode)

      const adventureTeamDoc = await getDoc(adventureTeamRef)
      if (adventureTeamDoc.exists()) {
        const adventureTeamData = adventureTeamDoc.data()
        if (!adventureTeamData.teamMembers.some(a => a.name === this.$store.state.userName)) {
          alert('Invalid Team Code, redirecting to homepage...')
          this.$store.commit('updateUserName', null)
          this.$store.commit('updateAdventureTeam', null)
          this.$router.push({ name: 'Start' })
        } else {
          document.title = `${adventureTeamData.adventureName}`
          this.adventureTeam = adventureTeamData
          this.$store.commit('updateAdventureTeam', adventureTeamData)
        }
      } else {
        alert('Invalid Team Code, redirecting to homepage...')
        this.$store.commit('updateUserName', null)
        this.$store.commit('updateAdventureTeam', null)
        this.$router.push({ name: 'Start' })
      }

      onSnapshot(adventureTeamRef, (doc) => {
        this.adventureTeam = doc.data()
        this.$store.commit('updateAdventureTeam', doc.data())
      })

      onSnapshot(adventureViewRef, (doc) => {
        if (!doc.data()) return
        this.adventureView = doc.data()
        this.$store.commit('updateAdventureView', doc.data())
      })
    }
  },
  mounted() {
    if (!(this.$store.state.teamCode && this.$store.state.userName)) {
      this.$router.push({ name: 'Start' })
    } else {
      this.fetchAdventureTeam(this.$store.state.teamCode)
    }

    document.addEventListener("visibilitychange", async () => {
      if (!document.hidden && this.pageInteractTimestamp){
        await firebaseApp.firestore().enableNetwork()
        const now = new Date()
        const diff = now.getTime() - this.pageInteractTimestamp.getTime()
        const sec_diff = diff / 1000
        if (
          sec_diff > 420 &&
          this.$route.name != 'Challenge'
        ) {
          location.reload()
        }
      } else {
        this.pageInteractTimestamp = new Date()
      }
    })
  },
  watch: {
    teamCode: {
      immediate: true,
      handler(teamCode) {
        if(teamCode){
          this.fetchAdventureTeam(teamCode)
        } else {
          this.$router.push({ name: 'Start' })
        }
      },
    },
    showPhotoViewer(newShowPhotoViewer){
      if(newShowPhotoViewer){
        this.showViewerJs()
      }
    },
    timeLimitText(newTimeLimitText, oldTimeLimitText) {
      if(
        oldTimeLimitText !== "TIME'S UP" && newTimeLimitText === "TIME'S UP"
      ){
        this.showTimesUpScreen = true
      }
    },
    adventureTeam(newAdventureTeam, oldAdventureTeam){
      if(oldAdventureTeam === null && newAdventureTeam){
        if(newAdventureTeam.stageDetails && !this.currentStage){
          this.$store.commit('updateCurrentStage', newAdventureTeam.stageDetails.stageNumber)
        }
        if (newAdventureTeam.uiMods && newAdventureTeam.uiMods.customFontUrl){
          this.setFontFamily(newAdventureTeam.uiMods.customFontUrl)
        }
        if (
          newAdventureTeam.allowTranslation &&
          newAdventureTeam.defaultLanguage &&
          this.$route.fullPath !== `${this.$route.path}#googtrans(${newAdventureTeam.defaultLanguage})`
        ){
          this.$router
          .replace(`${this.$route.path}#googtrans(${newAdventureTeam.defaultLanguage})`)
          .catch(err => {
            return err
          })
          window.location.reload()
        }
        // disable tutorial for now - Mohamed 12th April 2022
        // if (!this.$store.state.completedTutorial && newAdventureTeam.accessType !== 'public') {
        //   this.$router.push({ name: 'Tutorial' })
        // }
      }

      if(
        newAdventureTeam && newAdventureTeam.stageDetails && !this.currentStage
      ){
        this.$store.commit('updateCurrentStage', newAdventureTeam.stageDetails.stageNumber)
      }

      if (
        newAdventureTeam && newAdventureTeam.stage === 0
      ) {
        this.$router.push({ name: 'Lobby', params: { teamCode: newAdventureTeam.teamCode } })
      }

      if(
        newAdventureTeam && !oldAdventureTeam ||
        JSON.stringify(newAdventureTeam) !== JSON.stringify(oldAdventureTeam)
      ){
        if(newAdventureTeam.stageDetails){
          newAdventureTeam.stageDetails.challenges = newAdventureTeam.stageDetails.challenges.map((challenge, index) => {
            if(
              challenge.max_completions_per_session &&
              newAdventureTeam.challengeCompletions &&
              this.currentStage
            ){
              if (
                newAdventureTeam.challengeCompletions[this.currentStage] &&
                newAdventureTeam.challengeCompletions[this.currentStage][index]
              ) {
                challenge.completionsMade = newAdventureTeam.challengeCompletions[this.currentStage][index]
                challenge.completionsLeft = challenge.max_completions_per_session - newAdventureTeam.challengeCompletions[this.currentStage][index]
              } else {
                challenge.completionsMade = 0
                challenge.completionsLeft = challenge.max_completions_per_session
              }
            }
            return challenge
          })
        }
        this.$store.commit('updateAdventureTeam', newAdventureTeam)
      }

      if(oldAdventureTeam === null && newAdventureTeam && newAdventureTeam.time_limit_timestamp){
        const endTime = newAdventureTeam.time_limit_timestamp.toDate()
        this.getTimeLimitText(endTime)
        this.timeLimitInterval = setInterval(() => {
          this.getTimeLimitText(endTime)
        }, 1000);
      }

      if(oldAdventureTeam && newAdventureTeam &&
      oldAdventureTeam.answerLog.length === newAdventureTeam.answerLog.length &&
      oldAdventureTeam.answerLog[newAdventureTeam.answerLog.length-1] &&
      oldAdventureTeam.answerLog[newAdventureTeam.answerLog.length-1].isPendingJudgement &&
      !newAdventureTeam.answerLog[newAdventureTeam.answerLog.length-1].isPendingJudgement
      ){
        this.$store.commit('updateShowTaskAlert', true)
      }

      if(oldAdventureTeam && newAdventureTeam && oldAdventureTeam.items.length < newAdventureTeam.items.length){
        this.$store.commit('updateShowInventoryAlert', true)
      }

      if(oldAdventureTeam && newAdventureTeam && oldAdventureTeam.chats.length < newAdventureTeam.chats.length){
        if(newAdventureTeam.chats[newAdventureTeam.chats.length-1].name !== this.$store.state.userName){
          this.$store.commit('updateShowChatAlert', true)
        }
      }

      if(
        oldAdventureTeam && newAdventureTeam &&
        (
          (
            (
              oldAdventureTeam.powerups &&
              newAdventureTeam.powerups
            ) &&
            (
              !oldAdventureTeam.powerups && newAdventureTeam.powerups ||
              oldAdventureTeam.powerups.length < newAdventureTeam.powerups.length
            )
          )
          ||
          (
            !oldAdventureTeam.powerups &&
            newAdventureTeam.powerups
          )
        )
        ){
        this.$store.commit('updateShowPowerupAlert', true)
        if (newAdventureTeam.uiMods.powerupsShowRandomPowerupsPicker) {
          this.$router.push({ name: 'SelectPowerup' })
        }
      }

      if(oldAdventureTeam && newAdventureTeam && oldAdventureTeam.stage !== newAdventureTeam.stage){
        this.$store.commit('updateShowTaskAlert', false)
        // this.$store.commit('updateCurrentStage', newAdventureTeam.stage)
        this.$store.commit('updateShowNextStageAlert', true)
      }

      // remove after December 2023
      // if(oldAdventureTeam && newAdventureTeam && !oldAdventureTeam.completedAt && newAdventureTeam.completedAt){
      //   this.$router.push({ name: 'Debrief' })
      // }

      if(
        (
          // !oldAdventureTeam &&
          newAdventureTeam
        ) ||
        JSON.stringify(oldAdventureTeam.stageDetails) !== JSON.stringify(newAdventureTeam.stageDetails) &&
        newAdventureTeam.stageDetails &&
        newAdventureTeam.stageDetails.chapter_background_url
      ){
        if(
          newAdventureTeam.stageDetails &&
          newAdventureTeam.stageDetails.chapter_background_url
        ){
          document.querySelector('html').style.backgroundImage = `url('${newAdventureTeam.stageDetails.chapter_background_url}')`
        } else if (newAdventureTeam && newAdventureTeam.uiMods.defaultBackgroundColour) {
          document.querySelector('html').style.backgroundImage = null
          document.querySelector('html').style.backgroundColor = newAdventureTeam.uiMods.defaultBackgroundColour
        } else if (newAdventureTeam) {
          document.querySelector('html').style.backgroundColor = '#064343'
        }
        document.querySelector('html').style.backgroundPosition = 'left top'
        document.querySelector('html').style.backgroundAttachment = 'fixed'
        document.querySelector('html').style.backgroundRepeat = 'repeat-y'
        document.querySelector('html').style.backgroundSize = '100%'
      }

      if(
        newAdventureTeam && !oldAdventureTeam
      ){
        const uiMods = {
          game: newAdventureTeam?.uiMods?.game ?? 'Game',
          task: newAdventureTeam?.uiMods?.task ?? 'Task',
          chapter: newAdventureTeam?.uiMods?.chapter ?? 'Chapter',
          point: newAdventureTeam?.uiMods?.point ?? 'Point',
          clue: newAdventureTeam?.uiMods?.clue ?? 'Clue',
          buyClue: newAdventureTeam?.uiMods?.buyClue ?? 'Buy clue',
          showClue: newAdventureTeam?.uiMods?.showClue ?? 'Show clue',
          coin: newAdventureTeam?.uiMods?.coin ?? 'Coin',
          powerup: newAdventureTeam?.uiMods?.powerup ?? 'Powerup',
          powerupSubtext: newAdventureTeam?.uiMods?.powerupSubtext ?? null,
          chat: newAdventureTeam?.uiMods?.chat ?? 'Chat',
          chatSubtext: newAdventureTeam?.uiMods?.chatSubtext ?? null,
          scan: newAdventureTeam?.uiMods?.scan ?? 'Scan',
          scanSubtext: newAdventureTeam?.uiMods?.scanSubtext ?? null,
          inventory: newAdventureTeam?.uiMods?.inventory ?? 'Inventory',
          inventorySubtext: newAdventureTeam?.uiMods?.inventorySubtext ?? null,
          gallery: newAdventureTeam?.uiMods?.gallery ?? 'Gallery',
          gallerySubtext: newAdventureTeam?.uiMods?.gallerySubtext ?? null,
          facilitator: newAdventureTeam?.uiMods?.facilitator ?? 'Facilitator',
          hidePointsSubtext: newAdventureTeam?.uiMods?.hidePointsSubtext ?? false,
          hidePointsProgressBar: newAdventureTeam?.uiMods?.hidePointsProgressBar ?? false,
          hidePointsStarsWording: newAdventureTeam?.uiMods?.hidePointsStarsWording ?? false,
          hidePointsSystemSpecificChapters: newAdventureTeam?.uiMods?.hidePointsSystemSpecificChapters ?? false,
          hidePointsLeaderboard: newAdventureTeam?.uiMods?.hidePointsLeaderboard ?? false,
          sortByNameLeaderboard: newAdventureTeam?.uiMods?.sortByNameLeaderboard ?? false,
          loadingText: newAdventureTeam?.uiMods?.loadingText ?? false,
          loadingImageUrl: newAdventureTeam?.uiMods?.loadingImageUrl ?? false,
          defaultBackgroundColour: newAdventureTeam?.uiMods?.defaultBackgroundColour ?? false,
          waitForFaciHeader: newAdventureTeam?.uiMods?.waitForFaciHeader ?? null,
          waitForFaciText: newAdventureTeam?.uiMods?.waitForFaciText ?? null,
          tryAgainText: newAdventureTeam?.uiMods?.tryAgainText ?? null,
          customPrimaryButtonBackgroundColour: newAdventureTeam?.uiMods?.customPrimaryButtonBackgroundColour ?? null,
          customPrimaryButtonFontColour: newAdventureTeam?.uiMods?.customPrimaryButtonFontColour ?? null,
          customMainSectionBackgroundColour: newAdventureTeam?.uiMods?.customMainSectionBackgroundColour ?? null,
          customMainSectionFontColour: newAdventureTeam?.uiMods?.customMainSectionFontColour ?? null
        }
        this.$store.commit('updateUiMods', uiMods)

        if(uiMods.customPrimaryButtonBackgroundColour || uiMods.customPrimaryButtonFontColour){
          setInterval(() => {
            document.querySelectorAll('.button.is-primary-colors, .button.is-primary').forEach(div => {
              if(uiMods.customPrimaryButtonBackgroundColour && uiMods.customPrimaryButtonFontColour){
                div.setAttribute( 'style', `background-color: ${uiMods.customPrimaryButtonBackgroundColour} !important; color: ${uiMods.customPrimaryButtonFontColour} !important` )
              } else if (uiMods.customPrimaryButtonBackgroundColour){
                div.setAttribute( 'style', `background-color: ${uiMods.customPrimaryButtonBackgroundColour} !important` )
              } else if (uiMods.customPrimaryButtonFontColour){
                div.setAttribute( 'style', `color: ${uiMods.customPrimaryButtonFontColour} !important` )
              }
              div.classList.remove('glowing')
            })
          }, 100);
        }

        if(uiMods.customMainSectionBackgroundColour || uiMods.customMainSectionFontColour){
          setInterval(() => {
            document.querySelectorAll('.container .card, #navbar-bottom, .modal-card-body, .modal-content .card').forEach(div => {
              if(uiMods.customMainSectionBackgroundColour && uiMods.customMainSectionFontColour){
                div.setAttribute( 'style', `background-color: ${uiMods.customMainSectionBackgroundColour} !important; color: ${uiMods.customMainSectionFontColour} !important` )
              } else if (uiMods.customMainSectionBackgroundColour){
                div.setAttribute( 'style', `background-color: ${uiMods.customMainSectionBackgroundColour} !important` )
              } else if (uiMods.customMainSectionFontColour){
                div.setAttribute( 'style', `color: ${uiMods.customMainSectionFontColour} !important` )
              }
              div.classList.remove('glowing')
              div.classList.remove('box-shadow')
            })
            if (uiMods.customMainSectionFontColour) {
              document.querySelectorAll('.has-text-brand-color').forEach(div => {
                div.setAttribute( 'style', `color: ${uiMods.customMainSectionFontColour} !important` )
              })
              document.querySelectorAll('.fas.is-size-4.has-text-white.fa-arrow-circle-right.next-stage-nav-modifier.is-pulled-right')
              .forEach(div => {
                div.setAttribute( 'style', `color: ${uiMods.customMainSectionBackgroundColour} !important` )
              })
            }
          }, 25)
        }

        if(newAdventureTeam.uiMods.autoTranslateContent){
          setInterval(() => {
            document.querySelectorAll('.notranslate').forEach(div => {
              div.classList.remove('notranslate')
            })
          }, 250)
        }
      }

      if(!newAdventureTeam.teamMembers.some(a => a.name === this.$store.state.userName)) {
        alert('New device joined that has the same name, or exceeded max number of devices per team... removing this device from the game.')
        this.$store.commit('updateUserName', null)
        this.$store.commit('updateAdventureTeam', null)
        this.$router.push({ name: 'Start' })
      }

      // detect judged task result
      if(
        oldAdventureTeam && newAdventureTeam && oldAdventureTeam.answerLog && newAdventureTeam.answerLog &&
        oldAdventureTeam.answerLog.length == newAdventureTeam.answerLog.length &&
        oldAdventureTeam.answerLog.filter(log => log.isPendingJudgement).length > newAdventureTeam.answerLog.filter(log => log.isPendingJudgement).length
      ){
        const oldTasksToJudge = oldAdventureTeam.answerLog.filter(log => log.isPendingJudgement)
        const newTasksToJudge = newAdventureTeam.answerLog.filter(log => log.isPendingJudgement)
        const task = oldTasksToJudge.filter(log => {
          return !newTasksToJudge.some(log2 => {
            return log2.judgementId === log.judgementId
          })
        })[0]
        this.newJudgementResultPopUpTask = task
      }
    }
  }
}
</script>

<style>
.a{
  position: sticky;
  top: 0;
}

.navbar-margin {
  height: 60px;
}
</style>
