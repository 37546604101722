<template>
  <div class="container">
    <div class="card overflow-y overlapping-container has-top-shadow">
      <div class="card-content pb-6 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'leaderboard'}"/>
        <div class="container has-text-centered mb-3">
          <div class="py-4 is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            Leaderboard
          </div>
          <div
          v-if="adventureTeam.points !== adventureTeam.stagePoints"
          class="is-size-7 has-text-grey px-4">
            {{ $store.state.uiMods.point }}s on the leaderboard is different from the {{ $store.state.uiMods.point.toLowerCase() }}s shown on the main screen
            as it sums up all the points in every {{ $store.state.uiMods.chapter.toLowerCase() }},
            and can be affected by penalties and other adjustments.
          </div>
          <div v-if="hasFriendlyLeaderboard" class="is-size-6 has-text-grey mb-5 px-4">
            Teams ahead of you will have their {{ $store.state.uiMods.point.toLowerCase() }}s hidden.
            <br>
            {{ hidePositionsAbove && hidePositionsAbove > 0 ? `Teams between rank ${hidePositionsAbove} and ${ranking ? ranking.length : ''} will also be hidden.` : '' }}
          </div>
        </div>
        <div v-if="!ranking" class="container has-text-centered mb-3 px-5">
          <div class="title-skeleton-fullwidth loading mt-3"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
          <div class="title-skeleton-fullwidth loading mt-5"></div>
        </div>
        <div v-if="ranking" class="container has-text-centered mb-3">
          <div class="is-flex is-justify-content-space-between is-vcentered mb-1 pb-0">
            <div class="column is-2 has-text-weight-semibold">
              Rank
            </div>
            <div class="column is-4 has-text-weight-semibold">
              {{ adventureTeam.uiMods.clans ? (adventureTeam.uiMods.showClansAndTeamsLeaderboard ? 'Clan/Team' : 'Clan') : 'Team' }}
            </div>
            <div v-if="!$store.state.uiMods.hidePointsLeaderboard" class="column is-3 has-text-weight-semibold">
              {{ $store.state.uiMods.point }}s
            </div>
            <div v-if="adventureTeam.uiMods.clansAveragedPoints" class="column is-3 has-text-weight-semibold">
              Average
            </div>
            <div v-if="!hideTimingsLeaderboard" class="column is-3 has-text-weight-semibold">
              Time
            </div>
          </div>
          <div v-for="(row, index) in ranking" :key="index"
          v-bind:class="{ 'has-background-primary has-rounded-corners': row.teamName === adventureTeam.teamName && row.rank < hidePositionsAbove}"
          >
            <div class="is-flex is-justify-content-space-between is-vcentered my-1">
              <div class="column is-2" v-bind:class="{ 'has-text-weight-semibold': row.teamName === adventureTeam.teamName }">
                {{ !$store.state.uiMods.sortByNameLeaderboard ? row.rank : '' }}
                <i v-if="$store.state.uiMods.sortByNameLeaderboard" class="fas fa-eye-slash has-text-grey-light"></i>
              </div>
              <div v-if="adventureTeam.uiMods.alwaysShowTeamsNamesInFriendlyLeaderboard || (!hasFriendlyLeaderboard || (hasFriendlyLeaderboard && row.rank < hidePositionsAbove))" class="column is-4" :class="{ 'has-text-weight-semibold': row.teamName === adventureTeam.teamName }">
                {{ row.teamName }}
              </div>
              <div v-else class="column is-4" v-bind:class="{ 'has-text-weight-semibold': row.teamCode ===  teamCode }">
                <i class="fas fa-eye-slash has-text-grey-light"></i>
              </div>
              <div
                v-if="!$store.state.uiMods.hidePointsLeaderboard && (!hasFriendlyLeaderboard || (hasFriendlyLeaderboard && row.rank >= position && row.rank < hidePositionsAbove))"
                class="column is-3"
                :class="{ 'has-text-weight-bold': row.teamName === adventureTeam.teamName }">
                {{ row.points }}
              </div>
              <div v-else-if="!$store.state.uiMods.hidePointsLeaderboard" class="column is-3" v-bind:class="{ 'has-text-weight-semibold': row.teamCode ===  teamCode }">
                <i class="fas fa-eye-slash has-text-grey-light"></i>
              </div>
              <div v-if="adventureTeam.uiMods.clansAveragedPoints && (!hasFriendlyLeaderboard || (hasFriendlyLeaderboard && row.rank >= position && row.rank < hidePositionsAbove))" class="column is-3" :class="{ 'has-text-weight-bold': row.teamName === adventureTeam.teamName }">
                {{ row.points > 0 ? (row.points / row.teamMembers).toFixed(2) : 0 }}
              </div>
              <div v-else-if="adventureTeam.uiMods.clansAveragedPoints" class="column is-3" v-bind:class="{ 'has-text-weight-semibold': row.teamCode ===  teamCode }">
                <i class="fas fa-eye-slash has-text-grey-light"></i>
              </div>
              <div v-if="!hideTimingsLeaderboard && (!hasFriendlyLeaderboard || (hasFriendlyLeaderboard && row.rank >= position && row.rank < hidePositionsAbove))" class="column is-3" :class="{ 'has-text-weight-bold': row.teamName === adventureTeam.teamName }">
                {{ row.timeTakenToComplete ? new Date(row.timeTakenToComplete * 1000).toISOString().substr(11, 8) : '' }}
                {{ row.startedAt && !row.timeTakenToComplete ? new Date(((new Date()).getTime() - row.startedAt.seconds * 1000)).toISOString().substr(11, 8) : '' }}
                {{ !row.timeTakenToComplete && !row.startedAt ? '-' : ''}}
              </div>
              <div v-else-if="!hideTimingsLeaderboard " class="column is-3" v-bind:class="{ 'has-text-weight-semibold': row.teamCode ===  teamCode }">
                <i class="fas fa-eye-slash has-text-grey-light"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="container has-text-centered my-5 pb-5">
          <div @click="$router.push({ name: 'Stage' })" class="button is-small is-primary is-rounded">
            <i class="fas fa-clipboard mr-2"></i>
            Back to {{ $store.state.uiMods.task.toLowerCase() }}s page
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
import firebaseApp from '@/firebase/init'
import ViewsStat from '@/components/ViewsStat.vue'
import { getFirestore, doc, getDoc } from 'firebase/firestore'

const db = getFirestore(firebaseApp)

export default {
  name: 'Leaderboard',
  components: {
    ViewsStat,
    CardNavigation
  },
  data() {
    return {
      leaderboard: null
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    hideTimingsLeaderboard() {
      return this.adventureTeam.uiMods.hideTimingsLeaderboard || !!this.adventureTeam.uiMods.clans
    },
    hasFriendlyLeaderboard() {
      if(this.adventureTeam && this.adventureTeam.hasFriendlyLeaderboard){
        return true
      }
      return false
    },
    teamCode() {
      return this.$store.state.teamCode
    },
    position() {
      let position = 1
      if(this.ranking){
        this.ranking.forEach((el, index) => {
          if(el.teamName === this.adventureTeam.teamName){
            position = index + 1
          }
        })
      }
      return position
    },
    ranking() {
      if(this.leaderboard){
        const ranking = this.leaderboard.ranking.filter(el => {
          return el.sessionId === this.adventureTeam.sessionId
        })

        const teamRanking = ranking.map((el, index) => {
          return {
            rank: index + 1,
            ...el
          }
        }).sort((a, b) => {
          if (this.$store.state.uiMods.sortByNameLeaderboard) {
            return a.teamName.localeCompare(b.teamName)
          }
          return 1
        })

        let clanRanking = []
        if (this.adventureTeam.uiMods.clans) {
          clanRanking = this.adventureTeam.uiMods.clans.split(',')
          .map(clan => ({
            teamName: clan.trim(),
            points: ranking.reduce((sum, el) => el.clan === clan.trim() ? sum + el.points : sum, 0),
            teamMembers: ranking.filter(el => el.clan === clan.trim() && el.points > 0).length
          }))
          .sort((a, b) => b.points - a.points)
          .map((el, index) => ({ ...el, rank: index + 1 }))
        }

        if (this.adventureTeam.uiMods.clans && (this.adventureTeam.uiMods.showClansAndTeamsLeaderboard || this.adventureTeam.sessionId === 'LHES')) {
          clanRanking.map(clan => {
            clan.teamName = clan.teamName + ' (Clan)'
            return clan
          })
          return clanRanking.concat(teamRanking)
        } else if (this.adventureTeam.uiMods.clans) {
          return clanRanking
        } {
          return teamRanking
        }
      }
      return false
    },
    hidePositionsAbove() {
      if(this.ranking && this.hasFriendlyLeaderboard){
        const noOfTeams = this.ranking.length
        let hidePositionsAbove = 0
        if(noOfTeams > 3){
          hidePositionsAbove = noOfTeams - 3
        }
        if(noOfTeams * 0.3 > 3){
          hidePositionsAbove = noOfTeams - (noOfTeams * 0.3)
        }
        return parseInt(hidePositionsAbove + 1)
      }
      return 0
    }
  },
  watch: {
    adventureTeam: {
      immediate: true,
      handler(adventureTeam) {
        if(adventureTeam && adventureTeam.hideLeaderboard !== true){
          this.fetchLeaderboard(adventureTeam.adventureId)
        }
      }
    }
  },
  methods: {
    async fetchLeaderboard(adventureId) {
      const leaderboardDoc = await getDoc(doc(db, 'leaderboards', adventureId))
      if (leaderboardDoc.exists()) {
        this.leaderboard = leaderboardDoc.data()
      }
    }
  },
  mounted() {
    if(
      this.adventureTeam.hideLeaderboard &&
      !(
        this.adventureTeam.showLeaderboardAtCompletion &&
        this.adventureTeam.completedAt
      )
    ){
      this.$router.push({ name: 'Stage' })
    }
    this.$store.dispatch('enterPage', {
      pageName: 'leaderboard'
    })
  }
}
</script>
